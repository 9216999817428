import React from 'react'
import SocialProfile from '../homework/connect-social'

// Import the CSS file for social buttons styling
import '../../assets/css/bootstrap-social.css'

/**
 * Social component
 *
 * This component serves as a wrapper for the SocialProfile component,
 * providing a consistent layout and styling for the social profile section.
 */
const Social = () => {
  return (
    <div className="max-w-3xl">
      <header className="mb-6">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
          Connect Your Accounts
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Link your social accounts to enhance your profile and track your progress
        </p>
      </header>
      <main>
        <SocialProfile />
      </main>
    </div>
  )
}

export default Social
