import React, { useState, Suspense, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import Head from '../head'
import Header from './default/sections/header'
import Footer from './default/sections/footer'
import Register from './default/sections/modal'
import courseIds from '../../config/courses'
import { CourseCard } from './course-card'
import { LoadingSpinner } from "../ui/loading-spinner"
import { ErrorBoundary } from '../ui/error-boundary'

type Course = typeof courseIds[0] & {
  startDate: string;
  endDate: string;
}

interface CourseResponse {
  data: Array<{
    courseId: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
  }>;
}

const Landing = () => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false })
  const navigate = useNavigate()

  const [modalIsVisible, setModalVisibility] = useState(false)
  const [openedCourseId, setCourseId] = useState(courseIds[0].id)
  const [courses, setCourses] = useState<Course[]>(() => 
    courseIds.map(course => ({
      ...course,
      startDate: course.date.toISOString(),
      endDate: course.date.toISOString()
    }))
  )

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get<CourseResponse>('/api/v1/courses')
        const coursesData = response.data.data
        
        setCourses(courseIds.map(course => ({
          ...course,
          startDate: coursesData.find(c => c.courseId === course.id)?.startDate || course.date.toISOString(),
          endDate: coursesData.find(c => c.courseId === course.id)?.endDate || course.date.toISOString()
        })))
      } catch (error) {
        console.error('Failed to fetch courses:', error)
      }
    }

    fetchCourses()
  }, [])

  const { upcomingCourses, pastCourses } = useMemo(() => {
    const now = new Date()
    const sortByDate = (a: Course, b: Course) => {
      const dateA = new Date(a.startDate)
      const dateB = new Date(b.startDate)
      return dateA.getTime() - dateB.getTime() // Changed to ascending order
    }

    const sortedCourses = [...courses].sort(sortByDate)
    const upcoming: Course[] = []
    const past: Course[] = []

    for (const course of sortedCourses) {
      const startDate = new Date(course.startDate)
      if (startDate < now) {
        past.push(course)
      } else {
        upcoming.push(course)
      }
    }

    return {
      upcomingCourses: upcoming,
      pastCourses: past
    }
  }, [courses])

  const toggleModal = (courseId: string, registrationAllowed: boolean) => {
    return () => {
      setCourseId(courseId)
      if (!registrationAllowed) {
        setModalVisibility(!modalIsVisible)
      } else {
        const currentLang = i18n.language
        navigate(`/${currentLang}/enroll/${courseId}`)
        window.scrollTo(0, 0)
      }
    }
  }

  return (
    <>
      <Head
        title={t('courses.upcoming.seo.title')}
        keywords={t('courses.upcoming.seo.keywords')}
        description={t('courses.upcoming.seo.description')}
        subject={t('courses.upcoming.seo.subject')}
      />
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <Header />
        {modalIsVisible && (
          <Register 
            close={toggleModal(openedCourseId, courses.find(it => it.id === openedCourseId)?.registrationAllowed ?? false)} 
            courseId={openedCourseId} 
          />
        )}
        <main className="pt-20">
          <div className="container mx-auto px-4 py-8">
            <ErrorBoundary fallback={<div className="text-red-500 p-4 text-center">{t('courses.error.loading')}</div>}>
              <Suspense fallback={<LoadingSpinner />}>
                {upcomingCourses.length > 0 && (
                  <section className="mb-12">
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-6">
                      {t('courses.upcoming.title')}
                    </h2>
                    <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                      {upcomingCourses.map((course) => (
                        <CourseCard 
                          key={course.id}
                          course={course} 
                          toggleModal={toggleModal}
                        />
                      ))}
                    </div>
                  </section>
                )}

                {pastCourses.length > 0 && (
                  <section>
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-6">
                      {t('courses.past.title')}
                    </h2>
                    <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                      {pastCourses.map((course) => (
                        <CourseCard 
                          key={course.id}
                          course={course} 
                          toggleModal={toggleModal}
                        />
                      ))}
                    </div>
                  </section>
                )}
              </Suspense>
            </ErrorBoundary>
          </div>
        </main>
        <Footer />
      </div>
      <button 
        type="button"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-6 right-6 bg-primary hover:bg-primary-dark text-white p-2.5 rounded-full shadow-lg transition-all duration-300 opacity-80 hover:opacity-100"
        aria-label={t('common.scrollToTop')}
      >
        <i className="miu-icon-circle_arrow-up_glyph" />
      </button>
    </>
  )
}

export default Landing
