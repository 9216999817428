import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { sendWebTask } from '@data/reducers/courses'
import { useAppDispatch, useAppSelector } from '@data'
import type { HomeworkWebTasks } from '../../../interfaces/mongoose.gen'
import type { TaskStatus } from '../../../interfaces/homework'
import TaskHeader from '../common/TaskHeader'
import TaskError from '../common/TaskError'

interface Props {
  task: HomeworkWebTasks
  result: TaskStatus
  index: number
  testDataId: string
}

const WebTask: React.FC<Props> = ({ task, result, index, testDataId }) => {
  const dispatch = useAppDispatch()
  const homeworkId = useAppSelector((s) => s.authentication.user.homeworkId)
  const [url, setUrl] = useState(
    sessionStorage?.getItem(`${testDataId} - ${task.id}`) ||
    result?.url ||
    task.url ||
    ''
  )
  
  return (
    <div className="mt-8 mb-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <TaskHeader index={index} result={result.result} />
      
      <div className="prose prose-sm dark:prose-invert max-w-none mb-6">
        <ReactMarkdown>
          {task.task.replace(/\n/g, '  \n').split('HOMEWORK_ID').join(homeworkId || '')}
        </ReactMarkdown>
      </div>
      
      {result.result && <TaskError result={result.result} />}

      <div className="flex gap-4 items-center mt-6">
        <label 
          htmlFor={`url-${task.id}`} 
          className="text-gray-700 dark:text-gray-300 font-medium whitespace-nowrap"
        >
          Enter URL:
        </label>
        <div className="flex-grow">
          <input
            id={`url-${task.id}`}
            type="url"
            className="w-full px-4 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 
                     text-gray-900 dark:text-gray-100 rounded-md shadow-sm 
                     focus:ring-teal-500 dark:focus:ring-teal-400 
                     focus:border-teal-500 dark:focus:border-teal-400
                     placeholder-gray-400 dark:placeholder-gray-500"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
            placeholder="https://your-project-url.com"
          />
        </div>
        <button
          type="button"
          className="bg-teal-700 hover:bg-teal-600 dark:bg-teal-600 dark:hover:bg-teal-500 
                   text-white font-medium py-2 px-6 rounded-md transition-colors
                   focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-800 
                   focus:ring-teal-500 dark:focus:ring-teal-400"
          onClick={() => {
            if (task.id) {
              dispatch(sendWebTask(testDataId, task.id, url))
              sessionStorage?.setItem(`${testDataId} - ${task.id}`, url)
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default React.memo(WebTask)
