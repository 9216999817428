import React, { useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import Editor from '../../homework/coding/editor'
import type { EditorHandle } from '../../homework/coding/editor'
import TaskHeader from '../common/TaskHeader'
import TaskError from '../common/TaskError'
import type { HomeworkJsTasks } from '../../../interfaces/mongoose.gen'
import type { PartialTaskResult } from '../../../interfaces/homework'

interface Props {
  task: HomeworkJsTasks
  code: string
  homeworkCode: string
  testDataId: string
  result?: PartialTaskResult
  homeworkId: string
  onPrevTask?: () => void
  onNextTask?: () => void
  onCodeChange: (code: string) => void
  onReset: () => void
  onSubmit: (code: string) => void
  onExitFullscreen: () => void
}

const FullscreenEditor = ({
  task,
  code,
  homeworkCode,
  testDataId,
  result,
  homeworkId,
  onPrevTask,
  onNextTask,
  onCodeChange,
  onReset,
  onSubmit,
  onExitFullscreen
}: Props) => {
  const editorRef = useRef<EditorHandle>(null)

  const handleClearCode = () => {
    // Call the editor's reset function
    editorRef.current?.handleReset()
    // Call the parent's reset function
    onReset()
    // Update code in parent
    onCodeChange(task.code || '')
  }

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="bg-gradient-to-r from-teal-500/10 to-teal-500/5 dark:from-teal-500/20 dark:to-teal-500/10 px-4 py-3 border-b border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-center">
          <TaskHeader
            index={task.order ?? 0}
            result={result}
          />
          <button
            type="button"
            onClick={onExitFullscreen}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
            aria-label="Exit fullscreen"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <title>Exit fullscreen</title>
              <path fillRule="evenodd" d="M4 4a1 1 0 011-1h4a1 1 0 010 2H6.414l4.293 4.293a1 1 0 01-1.414 1.414L5 6.414V9a1 1 0 01-2 0V4zm12 12a1 1 0 01-1 1h-4a1 1 0 010-2h2.586l-4.293-4.293a1 1 0 011.414-1.414L15 13.586V11a1 1 0 012 0v5z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex overflow-hidden">
        {/* Left Column - Task Description */}
        <div className="w-1/3 border-r border-gray-200 dark:border-gray-700 flex flex-col">
          <div className="flex-1 overflow-y-auto">
            <div className="p-6">
              <div className="prose prose-sm dark:prose-invert max-w-none mb-6">
                <ReactMarkdown>
                  {task.task.replace(/\n/g, '  \n').split('HOMEWORK_ID').join(homeworkId)}
                </ReactMarkdown>
              </div>

              {/* Test Cases */}
              {task.testCases?.length > 0 && (
                <div className="space-y-4">
                  <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Test Cases</h3>
                  <div className="space-y-4">
                    {task.testCases.map((testCase, idx) => (
                      <div 
                        key={`${testCase.arguments}-${idx}`}
                        className="bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4 border border-gray-100 dark:border-gray-600"
                      >
                        <div className="space-y-4">
                          <div className="flex flex-col">
                            <span className="text-xs uppercase tracking-wider font-medium text-gray-500 dark:text-gray-400 mb-2">
                              Input
                            </span>
                            <code className="text-sm bg-white dark:bg-gray-800 px-3 py-2 rounded-md border border-gray-200 dark:border-gray-600">
                              {testCase.arguments}
                            </code>
                          </div>
                          {testCase.actions && (
                            <div className="flex flex-col">
                              <span className="text-xs uppercase tracking-wider font-medium text-gray-500 dark:text-gray-400 mb-2">
                                Actions
                              </span>
                              <code className="text-sm bg-white dark:bg-gray-800 px-3 py-2 rounded-md border border-gray-200 dark:border-gray-600">
                                {testCase.actions}
                              </code>
                            </div>
                          )}
                          <div className="flex flex-col">
                            <span className="text-xs uppercase tracking-wider font-medium text-gray-500 dark:text-gray-400 mb-2">
                              Expected Output
                            </span>
                            <code className="text-sm bg-white dark:bg-gray-800 px-3 py-2 rounded-md border border-gray-200 dark:border-gray-600">
                              {testCase.result}
                            </code>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Bottom Navigation */}
          <div className="border-t border-gray-200 dark:border-gray-700 p-4 bg-gray-50 dark:bg-gray-800">
            <div className="flex items-center justify-between mb-4">
              <button
                type="button"
                onClick={onPrevTask}
                disabled={!onPrevTask}
                className={`text-sm ${!onPrevTask ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed' : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}`}
              >
                ← Previous
              </button>
              <button
                type="button"
                onClick={onNextTask}
                disabled={!onNextTask}
                className={`text-sm ${!onNextTask ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed' : 'text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white'}`}
              >
                Next →
              </button>
            </div>
            <div className="flex flex-col space-y-2">
              <button
                type="button"
                onClick={handleClearCode}
                className="text-sm text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
              >
                Clear code
              </button>
              <button
                type="button"
                onClick={() => onSubmit(code)}
                className="w-full px-4 py-2 text-sm font-medium text-white bg-teal-600 border border-transparent rounded-md hover:bg-teal-700"
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {/* Right Column - Editor and Error */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="flex-1">
            <Editor
              ref={editorRef}
              defaultCode={task.code ?? ''}
              code={code}
              homeworkCode={homeworkCode}
              id={task.id || ''}
              onChange={onCodeChange}
              onReset={onReset}
              hideSubmit
            />
          </div>

          {result && (
            <div className="border-t border-gray-200 dark:border-gray-700 p-4 bg-gray-50 dark:bg-gray-800">
              <TaskError result={result} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FullscreenEditor
