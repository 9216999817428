import { useEffect, useState, useRef, type FC } from 'react'
import { Editor } from '../../common/text-editor/editor'
import { TextToSpeech } from './TextToSpeech'
import { MarkdownProcessor } from './MarkdownProcessor'
import { ErrorBoundary } from './ErrorBoundary'
import type { LessonContentProps } from './types'
import 'katex/dist/katex.min.css'

const LessonContentInner: FC<LessonContentProps> = ({ 
  adminEditMode, 
  description, 
  onSetDescription, 
  lessonId,
  title,
  onSetTitle 
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentMode, setCurrentMode] = useState(adminEditMode);
  const viewRecorded = useRef(false);

  useEffect(() => {
    const recordView = async () => {
      if (viewRecorded.current) {
        console.log('View already recorded');
        return;
      }
      
      if (!lessonId) {
        return;
      }
      console.log('Recording view for lesson:', lessonId);
      
      try {
        const response = await fetch(`/api/v1/courses/lesson/${lessonId}/increment-views`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
        
        if (!response.ok) {
          const error = await response.json();
          console.error('Failed to record view:', error);
          return;
        }
        
        const result = await response.json();
        console.log('View recorded successfully:', result);
        viewRecorded.current = true;
      } catch (error) {
        console.error('Failed to record lesson view:', error);
      }
    };

    recordView();
  }, [lessonId]);

  // Handle mode transitions
  useEffect(() => {
    if (currentMode !== adminEditMode) {
      setIsTransitioning(true);
      // Small delay to ensure clean unmount/mount
      const timer = setTimeout(() => {
        setCurrentMode(adminEditMode);
        setIsTransitioning(false);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [adminEditMode, currentMode]);

  if (isTransitioning) {
    return (
      <div className="mt-8 animate-pulse">
        <div className="h-96 bg-gray-100 dark:bg-gray-800 rounded-lg" />
      </div>
    );
  }

  return (
    <div className="mt-8 relative">
      {currentMode ? (
        <div key="editor-container" className="transition-opacity duration-200 space-y-4">
          {title && onSetTitle && (
            <div className="mb-4">
              <label htmlFor="lesson-title" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Lesson Title
              </label>
              <input
                id="lesson-title"
                type="text"
                value={title}
                onChange={(e) => onSetTitle(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                placeholder="Enter lesson title"
              />
            </div>
          )}
          <Editor 
            value={description} 
            onChange={onSetDescription}
            className="w-full"
          />
        </div>
      ) : (
        <>
          <MarkdownProcessor markdown={description} />
          <TextToSpeech text={description} />
        </>
      )}
    </div>
  );
};

const LessonContent: FC<LessonContentProps> = (props) => (
  <ErrorBoundary>
    <LessonContentInner {...props} />
  </ErrorBoundary>
);

export default LessonContent;
