import type { Reducer } from 'redux'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Homework } from '../../interfaces/mongoose.gen'
import type { AppDispatch, RootState } from '../index'

interface AdminHomeworksState {
  list: Homework[];
}

const GET_QUESTIONS = 'skillcrucial/admin/HOMEWORK/GET_QUESTIONS'
const GET_QUESTIONS_FAILED = 'skillcrucial/admin/HOMEWORK/GET_QUESTIONS_FAILED'

const UPDATE_ONE_HOMEWORK = 'skillcrucial/admin/HOMEWORK/UPDATE_ONE_HOMEWORK'
const UPDATE_ONE_HOMEWORK_FAILED = 'skillcrucial/admin/HOMEWORK/UPDATE_ONE_HOMEWORK_FAILED'

interface GetQuestionsAction {
  type: typeof GET_QUESTIONS;
  data: Homework[];
}

interface UpdateOneHomeworkAction {
  type: typeof UPDATE_ONE_HOMEWORK;
  homework: Homework;
  id: string;
}

interface GetQuestionsFailedAction {
  type: typeof GET_QUESTIONS_FAILED;
  error: Error;
}

interface UpdateOneHomeworkFailedAction {
  type: typeof UPDATE_ONE_HOMEWORK_FAILED;
  error: Error;
}

type AdminHomeworksAction =
  | GetQuestionsAction
  | UpdateOneHomeworkAction
  | GetQuestionsFailedAction
  | UpdateOneHomeworkFailedAction

const initialState: AdminHomeworksState = {
  list: []
}

const adminHomeworksSlice = createSlice({
  name: 'adminHomeworks',
  initialState,
  reducers: {
    getQuestions: (state, action: PayloadAction<Homework[]>) => {
      state.list = action.payload
    },
    updateOneHomework: (state, action: PayloadAction<{ homework: Homework, id: string }>) => {
      const { homework, id } = action.payload
      state.list = state.list.map((it) => {
        return it.id === id ? homework : it
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(all.fulfilled, (state, action) => {
      if (action.payload !== null)
        state.list = action.payload.homeworks
    })
    builder.addCase(saveHomework.fulfilled, (state, action) => {
      if (action.payload !== null)
        state.list.push(action.payload)
    })
  }
})

export const { getQuestions, updateOneHomework } = adminHomeworksSlice.actions

export default adminHomeworksSlice.reducer

export const all = createAsyncThunk<{homeworks: Homework[]}, void, {state: RootState}>(
  'admin-homework/getAll',
  async (_, { getState }) => {
    const { token } = getState().authentication

    return fetch('/api/v1/admin/homework', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    })
      .then((res) => res.json())
      .then((data) => data.data as { homeworks: Homework[] })
      .catch(() => {
        return { homeworks: [] }
      })
  })

export const saveHomework = createAsyncThunk<Homework, Homework, {state: RootState}>(
  'admin-homework/saveHomework',
  async (hw: Homework, { getState }) => {
    const { token } = getState().authentication
    return fetch('/api/v1/admin/homework', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ homework: hw })
    })
      .then((res) => res.json())
      .then((data) => (data.data as { homework: Homework }).homework)
  })
