import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface FullscreenButtonProps {
  isFullscreen: boolean
  onClick: () => void
}

const Tooltip = ({ text, buttonRect }: { text: string; buttonRect: DOMRect | null }) => {
  if (!buttonRect) return null

  const viewportHeight = window.innerHeight
  const top = Math.max(8, Math.min(buttonRect.top - 36, viewportHeight - 48))
  const left = Math.max(buttonRect.width / 2, Math.min(buttonRect.left + buttonRect.width / 2, window.innerWidth - buttonRect.width / 2))

  return createPortal(
    <div 
      style={{ top, left }}
      className="fixed z-[9999] px-3 py-1.5 text-xs font-medium text-white bg-gray-900/90 dark:bg-gray-800/90 rounded shadow-lg whitespace-nowrap transform -translate-x-1/2 backdrop-blur-sm ring-1 ring-white/10 transition-all duration-150 ease-in-out"
    >
      {text}
      <div className="absolute top-full left-1/2 transform -translate-x-1/2">
        <div className="w-2 h-2 bg-gray-900/90 dark:bg-gray-800/90 rotate-45 -mt-1 ring-1 ring-white/10" />
      </div>
    </div>,
    document.body
  )
}

const FullscreenButton: React.FC<FullscreenButtonProps> = ({ isFullscreen, onClick }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [buttonRect, setButtonRect] = useState<DOMRect | null>(null)

  const updateTooltip = (show: boolean, e?: React.MouseEvent<HTMLButtonElement>) => {
    if (show && e) {
      const rect = e.currentTarget.getBoundingClientRect()
      setButtonRect(rect)
    }
    setShowTooltip(show)
  }

  useEffect(() => {
    const handleScroll = () => {
      setShowTooltip(false)
    }

    const handleResize = () => {
      setShowTooltip(false)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <button
      type="button"
      onClick={onClick}
      onMouseEnter={(e) => updateTooltip(true, e)}
      onMouseLeave={() => updateTooltip(false)}
      className="relative p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 active:bg-gray-200 dark:active:bg-gray-600 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800"
      aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
    >
      {isFullscreen ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 text-gray-700 dark:text-gray-300 transform transition-transform duration-200" fill="none" stroke="currentColor" strokeWidth="2">
          <title>Exit fullscreen</title>
          <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 text-gray-700 dark:text-gray-300 transform transition-transform duration-200" fill="none" stroke="currentColor" strokeWidth="2">
          <title>Enter fullscreen</title>
          <path d="M3 3h7v7H3zM14 3h7v7h-7zM14 14h7v7h-7zM3 14h7v7H3z" />
        </svg>
      )}
      {showTooltip && (
        <Tooltip 
          text={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'} 
          buttonRect={buttonRect}
        />
      )}
    </button>
  )
}

export default FullscreenButton
