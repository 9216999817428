import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { toastr } from 'react-redux-toastr'
import { useAppSelector } from '@data'

interface ClickArgs {
  amount: number
  date: string
  paymentNumber: number
  courseId: string
  totalPayments: number
}

interface PaymentMethodType {
  _id: string
  details: {
    amount: number
    created: number
    description: string
    charges: {
      data: {
        paid: boolean
        refunded: boolean
        receipt_url: string
      }[]
    }
  }
  courseId: string
}

const PaymentMethod = () => {
  const [payments, setPaymentHistory] = useState<PaymentMethodType[]>([])
  const [paymentIsProgress, setPaymentProgress] = useState(false)

  const loadData = useCallback(async () => {
    try {
      const { data: pm } = await axios.get('/api/v1/billing/stripe/payment-history')
      setPaymentHistory(pm?.charges ?? [])
    } catch (error) {
      console.error('Failed to load payment history:', error)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onClickPaymentButton = ({ amount, date, paymentNumber, courseId, totalPayments }: ClickArgs) => async () => {
    if (paymentIsProgress) return
    setPaymentProgress(true)
    try {
      const { data: { charges, message, status } } = await axios.post('/api/v1/billing/stripe/make-payment', {
        amount,
        date,
        paymentNumber,
        courseId,
        totalPayments
      })
      setPaymentHistory(charges ?? [])
      const notify = status === 'error' ? toastr.error : toastr.success
      notify(status, message)
    } catch (error) {
      toastr.error('Error', 'Failed to process payment')
    } finally {
      setPaymentProgress(false)
    }
  }

  const paymentsIsDone = payments.filter((it) => {
    const isPaid = it.details.charges.data.some((d) => d.paid)
    const isRefunded = it.details.charges.data.some((d) => d.refunded)
    return isPaid && !isRefunded
  })

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="text-left border-b border-gray-200 dark:border-gray-700">
            <th className="pb-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">ID</th>
            <th className="pb-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">Amount</th>
            <th className="pb-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">Description</th>
            <th className="pb-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">Status</th>
            <th className="pb-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">Date</th>
            <th className="pb-3 text-xs font-medium text-gray-500 dark:text-gray-400 uppercase" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
          {payments.map((payment) => {
            const isPaid = payment.details.charges.data.some((d) => d.paid)
            const isRefunded = payment.details.charges.data.some((d) => d.refunded)
            const receiptUrl = payment.details.charges.data.find((d) => d.paid)?.receipt_url

            return (
              <tr key={payment._id} className="text-sm">
                <td className="py-4 text-gray-900 dark:text-gray-200">{payment._id}</td>
                <td className="py-4 text-gray-900 dark:text-gray-200">{payment.details.amount / 100} USD</td>
                <td className="py-4 text-gray-900 dark:text-gray-200">{payment.details.description}</td>
                <td className="py-4 text-gray-900 dark:text-gray-200">
                  {isRefunded ? 'Refunded' : isPaid ? 'Paid' : 'Not Paid'}
                </td>
                <td className="py-4 text-gray-900 dark:text-gray-200">
                  {dayjs(new Date(payment.details.created * 1000)).format('DD/MM/YYYY hh:mma')}
                </td>
                <td className="py-4 text-right">
                  {receiptUrl && (
                    <a
                      href={receiptUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                    >
                      Receipt
                    </a>
                  )}
                </td>
              </tr>
            )
          })}
          {paymentsIsDone.length !== 0 &&
            new Array(9 - paymentsIsDone.length).fill(null).map((_, index) => {
              const lastPayment = paymentsIsDone[paymentsIsDone.length - 1]
              const firstPayment = paymentsIsDone[0]
              const currPaymentIndex = paymentsIsDone.length + index
              const paymentId = `future-payment-${currPaymentIndex}`

              return (
                <tr key={paymentId} className="text-sm">
                  <td className="py-4 text-gray-900 dark:text-gray-200">{currPaymentIndex}</td>
                  <td className="py-4 text-gray-900 dark:text-gray-200">
                    {currPaymentIndex !== 1 ? (firstPayment.details.amount / 100) * 2 : firstPayment.details.amount / 100} USD
                  </td>
                  <td className="py-4 text-gray-900 dark:text-gray-200" />
                  <td className="py-4 text-gray-900 dark:text-gray-200">Not Paid</td>
                  <td className="py-4 text-gray-900 dark:text-gray-200">
                    {dayjs(new Date(lastPayment.details.created * 1000))
                      .add(index + 1, 'month')
                      .format('DD/MM/YYYY hh:mma')}
                  </td>
                  <td className="py-4 text-right">
                    {index === 0 && (
                      <button
                        onClick={onClickPaymentButton({
                          amount: currPaymentIndex !== 1 ? firstPayment.details.amount * 2 : firstPayment.details.amount,
                          date: dayjs(new Date(lastPayment.details.created * 1000))
                            .add(index + 1, 'month')
                            .format('DD/MM/YYYY hh:mma'),
                          paymentNumber: currPaymentIndex,
                          courseId: lastPayment.courseId,
                          totalPayments: 9
                        })}
                        type="button"
                        className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                        disabled={paymentIsProgress}
                      >
                        {paymentIsProgress ? 'Processing...' : 'Pay invoice'}
                      </button>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default PaymentMethod
