import { useState, useLayoutEffect, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import 'react-mde/lib/styles/css/react-mde-all.css'

import { useAppSelector } from '@data'
import type { HomeWorkQuestions } from '../../interfaces/mongoose.gen'
import Head from '../head'
import api from '../../services/api'
import InterviewBreadcrumb from './interview/InterviewBreadcrumb'
import ProgressBar from './interview/ProgressBar'
import SuccessBanner from './interview/SuccessBanner'
import SubmitButton from './interview/SubmitButton'
import QuestionItem from './interview/QuestionItem'
import AnsweredQuestions from './interview/AnsweredQuestions'
import type { CourseData, WeekData } from './interview/types'

interface ValidationState {
  [key: string]: boolean
}

interface AnswersState {
  [key: string]: string | string[]
}

const Interview = () => {
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const [questions, setQuestions] = useState<HomeWorkQuestions[]>([])
  const [validatation, setValidation] = useState<ValidationState>({})
  const [answers, setAnswers] = useState<AnswersState>({})
  const [isShaking, setIsShaking] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [correctQuestionIds, setCorrectQuestionIds] = useState<string[]>([])

  const { weekId, id: courseId } = useParams<{ weekId: string; id: string }>()

  const course = useAppSelector((s) => s.courses.list.find((it) => it?.courseId === courseId))
  const week = useAppSelector((s) =>
    (s.courses.list.find((it) => it?.courseId === courseId)?.weeks ?? []).find(
      (it) => it.weekId === weekId
    )
  )
  const weekIndex = useAppSelector((s) =>
    (s.courses.list.find((it) => it?.courseId === courseId)?.weeks ?? []).findIndex(
      (it) => it.weekId === weekId
    )
  )

  useEffect(() => {
    api.post('/courses/interview', { lessons: week?.lessonIds ?? [] }).then(({ data }) => {
      setQuestions(data.data)
    })
  }, [week])

  useLayoutEffect(() => {
    const element = document.getElementById('main-container')

    if (!isLoaded && element) {
      try {
        element.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } catch (error) {
        element.scrollTo(0, 0)
      }
      setLoaded(true)
    }
  }, [isLoaded])

  const scrollToFirstIncorrectQuestion = (incorrectAnswers: string[]) => {
    if (incorrectAnswers.length > 0) {
      const firstIncorrectQuestionId = incorrectAnswers[0]
      const element = document.querySelector(`[data-question-id="${firstIncorrectQuestionId}"]`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  const validateAnswer = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      const response = await api.post('/courses/interview/submit', {
        answers,
        weekIndex
      })

      if (response.data.success) {
        setIsSuccess(true)
        const element = document.getElementById('main-container')
        if (element) {
          try {
            element.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          } catch (error) {
            element.scrollTo(0, 0)
          }
        }
        toastr.success('🎉 Congratulations!', response.data.message)
      } else {
        setIsShaking(true)
        setTimeout(() => setIsShaking(false), 650) // Duration matches CSS animation
        
        // Mark incorrect answers
        const incorrectAnswers = response.data.incorrectAnswers || []
        const newValidation = incorrectAnswers.reduce((acc: ValidationState, questionId: string) => {
          acc[questionId] = true
          return acc
        }, {})
        setValidation(newValidation)
        
        // Determine correct answers
        const allQuestionIds = questions.map(q => q._id).filter(Boolean) as string[]
        const newCorrectQuestionIds = allQuestionIds.filter(id => 
          !incorrectAnswers.includes(id) && answers[id]
        )
        setCorrectQuestionIds(newCorrectQuestionIds)
        
        // Scroll to first incorrect answer
        scrollToFirstIncorrectQuestion(incorrectAnswers)
        
        toastr.error('Incorrect Answers', response.data.message)
      }
    } catch (error) {
      toastr.error('Error', 'Failed to submit answers. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  // Simulate checking if an answer is correct
  // In a real implementation, this would call an API endpoint
  const simulateCorrectAnswer = (questionId: string, answer: string | string[]): boolean => {
    // For demo purposes, we'll use a more deterministic approach
    // In a real implementation, this would be based on actual answer validation
    
    // Get the question
    const question = questions.find(q => q._id === questionId)
    if (!question) return false
    
    // For single choice and text questions
    if (typeof answer === 'string') {
      // For text questions, check if the answer is not empty
      if (question.type === 'text') {
        return answer.trim().length > 0
      }
      
      // For single choice, check if the answer matches one of the correct options
      // In this simulation, we'll consider the first option as the correct one
      const correctOption = question.options?.[0]?.text || ''
      return answer === correctOption
    }
    
    // For multiple choice questions
    if (Array.isArray(answer)) {
      // In this simulation, we'll consider the first two options as the correct ones
      // if the user has selected exactly those two options
      if (answer.length !== 2 || !question.options || question.options.length < 2) return false
      
      const correctOptions = question.options.slice(0, 2).map(opt => opt?.text || '').filter(Boolean)
      if (correctOptions.length !== 2) return false
      
      return answer.every(a => correctOptions.includes(a)) && 
             correctOptions.every(c => answer.includes(c))
    }
    
    return false
  }

  const handleAnswerChange = (questionId: string, value: string): void => {
    setValidation((prev: ValidationState) => Object.assign({}, prev, { [questionId]: false }))
    setAnswers((prev: AnswersState) => Object.assign({}, prev, { [questionId]: value }))
    
    // Remove from correct questions if the answer is changed
    if (correctQuestionIds.includes(questionId)) {
      setCorrectQuestionIds(prev => prev.filter(id => id !== questionId))
    }
    
    // Simulate checking if the answer is correct immediately
    const isCorrect = simulateCorrectAnswer(questionId, value)
    if (isCorrect) {
      // Mark as correct and add to correctQuestionIds after a short delay
      setTimeout(() => {
        setCorrectQuestionIds(prev => [...prev, questionId])
      }, 1000) // 1 second delay to allow for animation
    }
  }

  const handleMultiAnswerChange = (questionId: string, value: string): void => {
    setValidation((prev: ValidationState) => Object.assign({}, prev, { [questionId]: false }))
    
    // Update answers
    setAnswers((prev: AnswersState) => {
      const currentAnswers = prev[questionId] as string[] || []
      const newAnswers = currentAnswers.includes(value)
        ? currentAnswers.filter(answer => answer !== value)
        : [...currentAnswers, value]
      return { ...prev, [questionId]: newAnswers }
    })
    
    // Remove from correct questions if the answer is changed
    if (correctQuestionIds.includes(questionId)) {
      setCorrectQuestionIds(prev => prev.filter(id => id !== questionId))
    }
    
    // Get the updated answers array to check
    const currentAnswers = answers[questionId] as string[] || []
    const newAnswers = currentAnswers.includes(value)
      ? currentAnswers.filter(answer => answer !== value)
      : [...currentAnswers, value]
    
    // Simulate checking if the answer is correct immediately
    const isCorrect = simulateCorrectAnswer(questionId, newAnswers)
    if (isCorrect) {
      // Mark as correct and add to correctQuestionIds after a short delay
      setTimeout(() => {
        setCorrectQuestionIds(prev => [...prev, questionId])
      }, 1000) // 1 second delay to allow for animation
    }
  }

  // Adapter functions to convert Redux store data to component props
  const adaptCourseData = (course2: any): CourseData | undefined => {
    if (!course2) return undefined
    
    return {
      courseId: course2.courseId || '',
      name: course2.name,
      weeks: course2.weeks?.map((w: any) => ({
        weekId: w.weekId || '',
        name: w.name,
        lessonIds: w.lessonIds,
        lessons: w.lessons
      }))
    }
  }
  
  const adaptWeekData = (week2: any): WeekData | undefined => {
    if (!week2) return undefined
    
    return {
      weekId: week2.weekId || '',
      name: week2.name,
      lessonIds: week2.lessonIds,
      lessons: week2.lessons
    }
  }

  const answeredQuestions = Object.keys(answers).length
  const totalQuestions = questions.length
  
  // Filter out correctly answered questions
  const remainingQuestions = questions.filter(q => q._id && !correctQuestionIds.includes(q._id))
  
  // Adapt course and week data for components
  const adaptedCourse = adaptCourseData(course)
  const adaptedWeek = adaptWeekData(week)

  return (
    <div className="max-w-4xl mx-auto px-4 py-6">
      <Head title="Skillcrucial - Interview" />

      {isSuccess && (
        <SuccessBanner courseId={courseId} week={adaptedWeek} />
      )}

      <InterviewBreadcrumb course={adaptedCourse} week={adaptedWeek} courseId={courseId} weekId={weekId} />

      <ProgressBar answeredQuestions={answeredQuestions} totalQuestions={totalQuestions} />
      
      {/* Correctly Answered Questions Section */}
      <AnsweredQuestions 
        questions={questions} 
        answers={answers} 
        correctQuestionIds={correctQuestionIds} 
      />

      {/* Remaining Questions */}
      <div className="relative space-y-6 border-l-2 border-gray-600/30 ml-6 pl-10">
        {remainingQuestions.map((question, index) => {
          const questionId = question._id
          if (!questionId) return null

          const isAnswered = !!answers[questionId]
          const isIncorrect = validatation[questionId]
          const isCorrect = correctQuestionIds.includes(questionId)

          return (
            <QuestionItem
              key={questionId}
              question={question}
              index={index}
              questionId={questionId}
              isAnswered={isAnswered}
              isIncorrect={isIncorrect}
              isCorrect={isCorrect}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              handleMultiAnswerChange={handleMultiAnswerChange}
              courseId={courseId}
              weekId={weekId}
              course={adaptedCourse}
              week={adaptedWeek}
            />
          )
        })}
      </div>

      <SubmitButton
        isSubmitting={isSubmitting}
        isShaking={isShaking}
        answeredQuestions={answeredQuestions}
        totalQuestions={totalQuestions}
        onClick={validateAnswer}
      />
    </div>
  )
}

export default Interview
