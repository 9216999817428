import type { MouseEventHandler } from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Switch } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useAppSelector } from '@data'
import CheckoutForm from './checkout-form'

const stripePromise = process.env.STRIPE_PUBLIC_KEY ? loadStripe(process.env.STRIPE_PUBLIC_KEY) : null

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface MethodType {
  billing_details?: {
    name: string
    address: {
      country: string
      city: string
      line1: string
      line2: string
      postal_code: string
    }
  }
  card: {
    brand: string
    last4: string
  }
}

const PaymentMethod = () => {
  const [method, setPaymentMethods] = useState<MethodType | null>(null)
  const [enabled, setEnabled] = useState(true)

  const loadData = useCallback(async () => {
    try {
      const { data: pm } = await axios.get('/api/v1/billing/stripe/payment-methods')
      setPaymentMethods(pm as MethodType || null)
    } catch (error) {
      console.error('Failed to load payment methods:', error)
      setPaymentMethods(null)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  if (!method?.billing_details) return <div className="text-gray-500 dark:text-gray-300">Loading...</div>

  return (
    <div className="max-w-2xl">
      <div className="flex items-center justify-between mb-12">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">Payment Details</h3>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Your current billing payment details
          </p>
        </div>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? 'bg-blue-600' : 'bg-gray-200 dark:bg-gray-700',
            'relative inline-flex h-5 w-9 border-transparent rounded-full cursor-pointer transition-colors duration-200 ease-in-out'
          )}
        >
          <span className="sr-only">Change payment method</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-4' : 'translate-x-0',
              'pointer-events-none inline-block h-4 w-4 rounded-full bg-white transform ring-0 transition duration-200 ease-in-out mt-0.5 ml-0.5'
            )}
          />
        </Switch>
      </div>

      {enabled ? (
        <div className="space-y-8">
          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Name</div>
            <div className="text-sm text-gray-900 dark:text-gray-200">
              {method.billing_details.name}
            </div>
          </div>

          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Location</div>
            <div className="text-sm text-gray-900 dark:text-gray-200">
              {method.billing_details.address.city}, {method.billing_details.address.country}
            </div>
          </div>

          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Address</div>
            <div className="text-sm text-gray-900 dark:text-gray-200">
              <div>{method.billing_details.address.line1}</div>
              {method.billing_details.address.line2 && (
                <div className="mt-1">{method.billing_details.address.line2}</div>
              )}
              <div className="mt-1">{method.billing_details.address.postal_code}</div>
            </div>
          </div>

          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">Card</div>
            <div className="text-sm text-gray-900 dark:text-gray-200">
              <span className="capitalize">{method.card.brand}</span> ending in {method.card.last4}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {stripePromise && (
            <Elements stripe={stripePromise}>
              <CheckoutForm courseId="update-payment-method" />
            </Elements>
          )}
        </div>
      )}
    </div>
  )
}

export default PaymentMethod
