import React, { useRef, useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { sendJsTask, resetCode, codeUpdate } from '@data/reducers/courses'
import { useAppDispatch } from '@data'
import Editor from '../../homework/coding/editor'
import type { EditorHandle } from '../../homework/coding/editor'
import FullscreenButton from '../common/FullscreenButton'
import type { HomeworkJsTasks } from '../../../interfaces/mongoose.gen'
import type { PartialTaskResult } from '../../../interfaces/homework'
import TaskHeader from '../common/TaskHeader'
import TaskError from '../common/TaskError'

interface Props {
  code?: string
  task: HomeworkJsTasks
  messages?: Array<{ line: string }>
  result?: PartialTaskResult
  index: number
  taskIndex: number
  testDataId: string
  homeworkId: string
  onFullscreenToggle: () => void
}

const SingleJSTask: React.FC<Props> = ({ 
  code, 
  task, 
  messages = [], 
  result, 
  index,
  taskIndex,
  testDataId, 
  homeworkId,
  onFullscreenToggle
}) => {
  const dispatch = useAppDispatch()
  const editorRef = useRef<EditorHandle>(null)

  const [currentCode, setCurrentCode] = useState(code || task.code || '')

  useEffect(() => {
    // Update current code when code prop changes
    if (code) {
      setCurrentCode(code)
    }
  }, [code])

  const handleCodeChange = (newCode: string) => {
    if (task.id) {
      setCurrentCode(newCode)
      // Update Redux store
      dispatch(codeUpdate({
        homeworkId: testDataId,
        taskId: task.id,
        code: newCode
      }))
    }
  }

  const handleReset = () => {
    if (task.id) {
      const initialCode = task.code || ''
      setCurrentCode(initialCode)
      // Reset code in Redux
      dispatch(resetCode({
        homeworkId: testDataId,
        taskId: task.id,
        code: initialCode
      }))
      // Reset editor
      editorRef.current?.handleReset()
    }
  }

  const handleSubmit = (submittedCode: string) => {
    if (task.id) {
      dispatch(sendJsTask(testDataId, task.id, submittedCode))
    }
  }

  return (
    <div className="mb-12">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
        {/* Task Header */}
        <div className="relative bg-gradient-to-r from-teal-500/10 to-teal-500/5 dark:from-teal-500/20 dark:to-teal-500/10 px-4 py-3 border-b border-gray-200 dark:border-gray-700 overflow-visible">
          <div className="flex justify-between items-center overflow-visible">
            <TaskHeader 
              index={taskIndex}
              result={result}
            />
            <FullscreenButton isFullscreen={false} onClick={onFullscreenToggle} />
          </div>
        </div>

        <div className="px-6 py-5">
          <div className="prose prose-sm dark:prose-invert max-w-none mb-6">
            <ReactMarkdown>
              {task.task.replace(/\n/g, '  \n').split('HOMEWORK_ID').join(homeworkId)}
            </ReactMarkdown>
          </div>

          {/* Test Cases */}
          {task.testCases?.length > 0 && (
            <div className="space-y-4 mb-8">
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Test Cases</h3>
              <div className="grid gap-4">
                {task.testCases.map((testCase, idx) => (
                  <div 
                    key={`${testCase.arguments}-${idx}`}
                    className="bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4 border border-gray-100 dark:border-gray-600"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      <div className="flex flex-col">
                        <span className="text-xs uppercase tracking-wider font-medium text-gray-500 dark:text-gray-400 mb-2">
                          Input
                        </span>
                        <code className="text-sm bg-white dark:bg-gray-800 px-3 py-2 rounded-md border border-gray-200 dark:border-gray-600">
                          {testCase.arguments}
                        </code>
                      </div>
                      {testCase.actions && (
                        <div className="flex flex-col">
                          <span className="text-xs uppercase tracking-wider font-medium text-gray-500 dark:text-gray-400 mb-2">
                            Actions
                          </span>
                          <code className="text-sm bg-white dark:bg-gray-800 px-3 py-2 rounded-md border border-gray-200 dark:border-gray-600">
                            {testCase.actions}
                          </code>
                        </div>
                      )}
                      <div className="flex flex-col">
                        <span className="text-xs uppercase tracking-wider font-medium text-gray-500 dark:text-gray-400 mb-2">
                          Expected Output
                        </span>
                        <code className="text-sm bg-white dark:bg-gray-800 px-3 py-2 rounded-md border border-gray-200 dark:border-gray-600">
                          {testCase.result}
                        </code>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Error Display */}
          {result && (
            <div className="mb-8">
              <TaskError result={result} />
            </div>
          )}

          {/* Code Editor */}
          <div className="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-600">
            <Editor
              ref={editorRef}
              defaultCode={task.code ?? ''}
              code={currentCode}
              homeworkCode={code || ''}
              id={task.id || ''}
              onChange={handleCodeChange}
              onReset={handleReset}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SingleJSTask)
