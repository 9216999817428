import { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { passwordStrength } from 'check-password-strength'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import axios from 'axios'

type PasswordStrength = 'Too weak' | 'Weak' | 'Medium' | 'Strong'

const PasswordRules = () => (
  <div className="text-xs text-gray-500 dark:text-gray-400 space-y-1 mt-4">
    <div>Password must contain:</div>
    <div>• At least 8 characters</div>
    <div>• At least one uppercase letter</div>
    <div>• At least one lowercase letter</div>
    <div>• At least one number</div>
    <div>• At least one special character</div>
  </div>
)

const PasswordStrengthIndicator = ({ password }: { password: string }) => {
  if (!password) return null
  
  const strength = passwordStrength(password).value as PasswordStrength
  const colors: Record<PasswordStrength, string> = {
    'Too weak': 'bg-red-500',
    'Weak': 'bg-orange-500',
    'Medium': 'bg-yellow-500',
    'Strong': 'bg-green-500'
  }
  
  return (
    <div className="mt-2">
      <div className="flex items-center justify-between mb-1">
        <div className="text-xs font-medium text-gray-500 dark:text-gray-400">Password strength:</div>
        <div className={`text-xs font-medium ${
          strength === 'Strong' ? 'text-green-500' :
          strength === 'Medium' ? 'text-yellow-500' :
          strength === 'Weak' ? 'text-orange-500' :
          'text-red-500'
        }`}>
          {strength}
        </div>
      </div>
      <div className="h-1 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
        <div className={`h-1 ${colors[strength]} transition-all duration-300`} style={{
          width: strength === 'Strong' ? '100%' :
                strength === 'Medium' ? '66%' :
                strength === 'Weak' ? '33%' : '10%'
        }} />
      </div>
    </div>
  )
}

const ChangePassword = () => {
  const [newPassword, setPassword] = useState('')
  const [newRepeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const changePasswordHandler = async (e: React.FormEvent) => {
    e.preventDefault()
    if (newPassword !== newRepeatPassword) {
      setError('Passwords do not match')
      return
    }
    if (!['Strong', 'Medium'].includes(passwordStrength(newPassword).value)) {
      setError('Please choose a stronger password')
      return
    }
    setError('')

    try {
      await axios.post('/api/v1/user/change-password', {
        password: newPassword
      })
      setPassword('')
      setRepeatPassword('')
      toastr.success('Success', 'Password was successfully changed')
    } catch (err) {
      setError('Failed to change password. Please try again.')
    }
  }

  return (
    <form className="max-w-md space-y-6" onSubmit={changePasswordHandler}>
      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          New Password
        </label>
        <div className="mt-1 relative">
          <input
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            required
            value={newPassword}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full py-1.5 text-sm border-0 border-b border-gray-300 dark:border-gray-600 bg-transparent text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-0 focus:border-blue-500 transition-colors duration-200"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            {showPassword ? (
              <EyeSlashIcon className="h-4 w-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300" />
            ) : (
              <EyeIcon className="h-4 w-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300" />
            )}
          </button>
        </div>
        <PasswordStrengthIndicator password={newPassword} />
      </div>
      
      <div>
        <label htmlFor="repeat-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Confirm Password
        </label>
        <div className="mt-1 relative">
          <input
            id="repeat-password"
            name="repeat-password"
            type={showConfirmPassword ? 'text' : 'password'}
            autoComplete="new-password"
            required
            value={newRepeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            className="block w-full py-1.5 text-sm border-0 border-b border-gray-300 dark:border-gray-600 bg-transparent text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-0 focus:border-blue-500 transition-colors duration-200"
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            {showConfirmPassword ? (
              <EyeSlashIcon className="h-4 w-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300" />
            ) : (
              <EyeIcon className="h-4 w-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300" />
            )}
          </button>
        </div>
      </div>

      {error && (
        <div className="text-sm text-red-600 dark:text-red-400" role="alert">
          {error}
        </div>
      )}

      <PasswordRules />

      <div className="flex justify-end">
        <button
          type="submit"
          className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 transition-colors duration-200"
        >
          Update Password
        </button>
      </div>
    </form>
  )
}

export { ChangePassword as default }
