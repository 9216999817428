import React from 'react'

const Loader = ({ loading = true }) => {
  if (!loading) return null

  // Generate unique keys for cubes
  const cubeKeys = Array(6)
    .fill(0)
    .map(() => Math.random().toString(36).substr(2, 9))

  return (
    <div className="flex w-screen h-screen justify-center items-center z-[100] absolute left-0 top-0">
      <div className="relative w-[180px] h-[100px]">
        {/* Blockchain-style animated cubes */}
        <div className="absolute inset-0 grid grid-cols-3 gap-2">
          {cubeKeys.map((key, i) => (
            <div
              key={key}
              className="relative"
              style={{
                animation: `blockchainFloat ${1 + i * 0.2}s ease-in-out infinite alternate`,
                animationDelay: `${i * 0.1}s`
              }}
            >
              <div
                className="w-full h-full bg-[#2c7a7b] rounded-lg opacity-30 absolute"
                style={{
                  animation: 'blockchainPulse 1.5s ease-in-out infinite',
                  animationDelay: `${i * 0.1}s`
                }}
              />
              <div
                className="w-full h-full border-2 border-[#2c7a7b] rounded-lg absolute"
                style={{
                  animation: 'blockchainGlow 1.5s ease-in-out infinite',
                  animationDelay: `${i * 0.1}s`
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <style>
        {`
          @keyframes blockchainFloat {
            0% { transform: translateY(0px) rotate(0deg); }
            100% { transform: translateY(-10px) rotate(5deg); }
          }
          @keyframes blockchainPulse {
            0% { transform: scale(0.95); opacity: 0.3; }
            50% { transform: scale(1); opacity: 0.4; }
            100% { transform: scale(0.95); opacity: 0.3; }
          }
          @keyframes blockchainGlow {
            0% { box-shadow: 0 0 5px #2c7a7b33; }
            50% { box-shadow: 0 0 20px #2c7a7b66; }
            100% { box-shadow: 0 0 5px #2c7a7b33; }
          }
        `}
      </style>
    </div>
  )
}

export default React.memo(Loader)
