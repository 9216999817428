import type { HomeworkWebTasks } from '../../../interfaces/mongoose.gen'
import type { TaskResultMap, TaskStatus } from '../../../interfaces/homework'
import WebTask from './web-task'

interface Props {
  tasks: HomeworkWebTasks[]
  results: TaskResultMap
  testDataId: string
  homeworkId: string // This prop is not used in WebTask but needed for the parent component
}

const WebTasks: React.FC<Props> = ({ tasks, results = {}, testDataId }) => {
  const getTaskStatus = (taskId: string): TaskStatus => {
    if (!taskId || !results[taskId]) {
      return {
        result: {
          status: undefined,
          messages: []
        }
      }
    }
    return results[taskId]
  }

  return (
    <div className="space-y-8 divide-y divide-gray-100 dark:divide-gray-700">
      {tasks.map((task, index) => {
        if (!task.id) return null
        
        const taskStatus = getTaskStatus(task.id)
        
        return (
          <div key={task.id} className="pt-8 first:pt-0">
            <WebTask
              task={task}
              testDataId={testDataId}
              index={index}
              result={taskStatus}
            />
          </div>
        )
      })}
    </div>
  )
}

export default WebTasks
