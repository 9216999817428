import type React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import { getLanguage } from '../../../config/i18n'
import { TextQuestion, SingleQuestion, MultipleQuestion } from './question-types'
import type { QuestionItemProps } from './types'

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  index,
  questionId,
  isAnswered,
  isIncorrect,
  isCorrect,
  answers,
  handleAnswerChange,
  handleMultiAnswerChange,
  courseId,
  weekId,
  course,
  week
}) => {
  return (
    <div
      data-question-id={questionId}
      className={classNames(
        'rounded-xl shadow-xl p-6 backdrop-blur-sm transition-all duration-300 mb-8 relative',
        {
          'bg-gradient-to-r from-red-900/20 to-red-800/10 border-l-4 border border-red-500/50 shadow-red-900/20': isIncorrect,
          'bg-gradient-to-r from-green-900/20 to-green-800/10 border-l-4 border border-green-500/50 shadow-green-900/20 animate-success-then-fade': isCorrect,
          'bg-gray-800/90 border border-gray-700/50 hover:border-teal-500/30': !isIncorrect && !isCorrect
        }
      )}
    >
      {/* Question Number Circle */}
      <div className={classNames(
        'absolute -left-[42px] w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium -translate-x-1/2 transition-all duration-300 shadow-md',
        {
          'bg-red-500/20 text-red-300 border-2 border-red-500 ring-4 ring-red-500/10': isIncorrect,
          'bg-teal-500/20 text-teal-300 border-2 border-teal-500 ring-4 ring-teal-500/10': isAnswered && !isIncorrect,
          'bg-gray-800 text-gray-400 border-2 border-gray-600/50': !isAnswered
        }
      )}>
        {index + 1}
      </div>

      {/* Question Content */}
      <div className="flex-1">
        <h2
          className={classNames('text-xl font-medium mb-4', {
            'text-red-400': isIncorrect,
            'text-gray-100': !isIncorrect
          })}
        >
          <ReactMarkdown>{question.question}</ReactMarkdown>
        </h2>
        
        {/* Source Lesson Link - Only show if there are actual lessons */}
        {question.lessonIds && question.lessonIds.length > 0 && (
          <div className="mb-6 flex items-center">
            <span className="text-xs text-gray-400 mr-2">From lesson:</span>
            <div className="flex flex-wrap gap-2">
              {question.lessonIds.map((lessonId) => {
                // First try to find lesson in current week
                const lesson = week?.lessons?.find((l) => l._id === lessonId || l.id === lessonId)
                
                // If not found in current week, try all weeks in the course
                if (!lesson) {
                  const allLessons = course?.weeks?.flatMap(w => w.lessons || []) || []
                  const lessonFromCourse = allLessons.find(l => l.id === lessonId)
                  if (!lessonFromCourse) return null
                  
                  // Find the week that contains this lesson
                  const lessonWeek = course?.weeks?.find(w => 
                    w.lessons?.some(l => l.id === lessonId)
                  )
                  
                  return (
                    <Link
                      key={lessonId}
                      to={`/${getLanguage()}/course/${courseId}/${lessonWeek?.weekId || weekId}/${lessonId}`}
                      className="text-xs px-2 py-1 bg-teal-600/20 text-teal-400 rounded-md hover:bg-teal-600/30 transition-colors flex items-center"
                    >
                      <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <title>Lesson source</title>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {lessonFromCourse.name}
                    </Link>
                  )
                }
                
                return (
                  <Link
                    key={lessonId}
                    to={`/${getLanguage()}/course/${courseId}/${weekId}/${lessonId}`}
                    className="text-xs px-2 py-1 bg-teal-600/20 text-teal-400 rounded-md hover:bg-teal-600/30 hover:text-white transition-colors flex items-center"
                  >
                    <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <title>Lesson source</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    {lesson.name}
                  </Link>
                )
              })}
            </div>
          </div>
        )}

        {question.type === 'text' && (
          <TextQuestion
            questionId={questionId}
            isIncorrect={isIncorrect}
            value={answers[questionId] as string || ''}
            onChange={handleAnswerChange}
          />
        )}

        {question.type === 'single' && (
          <SingleQuestion
            questionId={questionId}
            options={question.options}
            selectedValue={answers[questionId] as string || ''}
            onChange={handleAnswerChange}
          />
        )}

        {question.type === 'multiple' && (
          <MultipleQuestion
            questionId={questionId}
            options={question.options}
            selectedValues={Array.isArray(answers[questionId]) ? answers[questionId] as string[] : []}
            onChange={handleMultiAnswerChange}
          />
        )}
      </div>
    </div>
  )
}

export default QuestionItem
