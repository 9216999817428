import { useState, useEffect, useRef, type ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { getLanguage } from '../../config/i18n';
import { useAppDispatch, useAppSelector } from '@data';
import { loadLessons, getQuestionsListForLesson, getHomeworkForLesson } from '@data/reducers/courses';
import { updateLesson, createLesson } from '@data/reducers/admin-courses';
import { selectHomeworkData, selectHomeworkStatuses } from '@data/selectors/homework';
import type { Lesson as LessonType, LessonRating, Course, LessonResource } from '../../interfaces/mongoose.gen';
import type { InterviewQuestion } from '../../interfaces/interview';

import CourseDetailsModal from './CourseDetailsModal';
import ResourceEditor from './resource-editor';
import InterviewQuestionsEditor from './interview-questions-editor';

import Breadcrumb from './breadcrumb';
import VideoPlayer from './video-player';
import Rating from './rating';
import AdminControls from './admin-controls';
import FloatingSaveButton from './floating-save-button';
import NavigationButtons from './navigation-buttons';
import LessonContent from './lesson-content';
import ResourcesList from './resources-list';
import Homework from './homework';
import LessonQuestionSection from './lesson-question-section';
import Head from '../head';
import Loader from '../common/loader';

const Lesson = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { id: courseId = '', weekId = '', lessonId = '' } = useParams();
  const [isLoaded, setLoaded] = useState(false);
  const [adminEditMode, setAdminEditMode] = useState(false);
  const [isDescriptionDirty, setDescriptionDirty] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [newResource, setNewResource] = useState({ text: '', link: '' });
  const [newQuestion, setNewQuestion] = useState('');
  const [resources, setResources] = useState<LessonResource[]>([]);
  const [interviewQuestions, setInterviewQuestions] = useState<InterviewQuestion[]>([]);

  const user = useAppSelector((s) => s.authentication.user);
  const course = useAppSelector((s) => s.courses.list?.find((it) => it.courseId === courseId));
  const week = useAppSelector((s) => 
    course?.weeks?.find((it) => it.weekId === weekId) 
  );
  const lesson = week?.lessons.find((it) => it._id === lessonId) || {
    name: '',
    tags: [],
    types: [],
    timecodes: [],
    images: [],
    resources: [],
    order: 0
  } as LessonType;
  const [description, setDescriptionValue] = useState(lesson?.description || '');
  const [title, setTitle] = useState(lesson?.name || '');

  const courseName = course?.name || 'unknown';
  const role = user?.role || 'user';
  const questions = useAppSelector((s) => s.courses.questions[lessonId] || []);
  const homework = useAppSelector((s) => lesson.homeworkId ? selectHomeworkData(s, lesson.homeworkId) : undefined);
  const homeworkStatuses = useAppSelector((s) => lesson.homeworkId ? selectHomeworkStatuses(s, lesson.homeworkId) : {});

  useEffect(() => {
    if (week && !week.isLoaded && week.lessonIds && week.lessonIds.length > 0) {
      dispatch(loadLessons(week));
    }
  }, [week, dispatch]);

  useEffect(() => {
    if (lessonId) {
      dispatch(getQuestionsListForLesson(lessonId));
      if (lesson.homeworkId) {
        dispatch(getHomeworkForLesson(lesson.homeworkId));
      }
    }
  }, [lessonId, lesson.homeworkId, dispatch]);

  useEffect(() => {
    setDescriptionValue(lesson.description || '');
    setTitle(lesson.name || '');
    setResources(lesson.resources || []);
    setInterviewQuestions(lesson.interviewQuestions || []);
  }, [lesson.description, lesson.name, lesson.resources, lesson.interviewQuestions]);

  useEffect(() => {
    const element = document.getElementById('main-container');
    if (!isLoaded && element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
      setLoaded(true);
    }
  }, [isLoaded]);

  const handleToggleAdminEditMode = (): void => {
    // If we're exiting edit mode and there are unsaved changes, save them
    if (adminEditMode && isDescriptionDirty) {
      handleSaveDescription();
    } else {
      setAdminEditMode(!adminEditMode);
    }
  };

  const handleSaveDescription = (): void => {
    setDescriptionDirty(false);
    setAdminEditMode(false);
    dispatch(updateLesson({ 
      ...lesson, 
      description,
      name: title,
      resources,
      interviewQuestions
    }));
  };

  const handleAddResource = (resource: { text: string; link: string }): void => {
    const newResource2 = {
      ...resource,
      id: `temp-${Date.now()}`
    };
    setResources([...resources, newResource2]);
    setDescriptionDirty(true);
  };

  const handleRemoveResource = (resourceId: string): void => {
    setResources(resources.filter(r => (r.id || r._id) !== resourceId));
    setDescriptionDirty(true);
  };

  const handleAddQuestion = (question: InterviewQuestion): void => {
    setInterviewQuestions([...interviewQuestions, question]);
    setDescriptionDirty(true);
  };

  const handleAddQuestions = (questions2: InterviewQuestion[]): void => {
    setInterviewQuestions([...interviewQuestions, ...questions2]);
    setDescriptionDirty(true);
  };

  const handleRemoveQuestion = (questionId: string): void => {
    setInterviewQuestions(interviewQuestions.filter(q => q._id !== questionId));
    setDescriptionDirty(true);
  };

  const handleSetDescription = (val: string): void => {
    setDescriptionDirty(true);
    setDescriptionValue(val);
  };

  const handleSetTitle = (val: string): void => {
    setDescriptionDirty(true);
    setTitle(val);
  };

  const navigate = useNavigate();
  
  // Simple hash function to generate unique IDs
  const generateHash = (input: string): string => {
    // Use a simple hash algorithm for the browser
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(16);
  };
  
  const handleAddLessonBefore = async (): Promise<void> => {
    if (!week || !courseId) return;
    
    // Check if the current lesson is empty
    if (!lesson.description || lesson.description.trim() === '') {
      toastr.error('Error', 'Cannot add a lesson before an empty lesson. Please add content to the current lesson first.');
      return;
    }
    
    // Generate a unique ID based on current lesson ID and position
    const uniqueId = generateHash(`${lessonId}-before-${Date.now()}`);
    
    // Create a new lesson with default values
    const newLesson: Partial<LessonType> = {
      name: 'New Lesson',
      description: '# New Lesson\n\nAdd your content here.',
      resources: [],
      interviewQuestions: [],
      weekId: weekId,
      tags: [],
      types: [],
      timecodes: [],
      images: [],
      type: 'text', // Required field
      _id: uniqueId, // Use the generated hash as ID
      id: uniqueId, // Use the generated hash as ID
      order: lesson.order ? lesson.order - 0.5 : 0 // Position before current lesson
    };
    
    try {
      // First create the lesson on the server
      const result = await dispatch(createLesson(newLesson as LessonType));
      
      if (result && typeof result === 'object' && 'lesson' in result && result.lesson && week) {
        const newLessonData = result.lesson as LessonType;
        
        // Then update the Redux store with the new lesson
        dispatch({
          type: 'courses/createLesson',
          payload: {
            courseId,
            week,
            data: newLessonData
          }
        });
        
        // Invalidate the Redis cache to ensure the new lesson is visible
        try {
          await fetch('/api/v1/courses/invalidate-cache', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'same-origin' // Include cookies for authentication
          });
        } catch (cacheError) {
          console.error('Failed to invalidate cache:', cacheError);
          // Continue even if cache invalidation fails
        }
        
        // Reload the week to get the updated lessons
        dispatch(loadLessons(week));
        
        // Show success notification
        toastr.success('Lesson Created', 'New lesson has been added before the current lesson');
        
        // Ask if user wants to navigate to the new lesson
        toastr.confirm('Would you like to navigate to the new lesson?', {
          onOk: () => {
            if (newLessonData?._id) {
              navigate(`/${getLanguage()}/course/${courseId}/${weekId}/${newLessonData._id}`);
            }
          }
        });
      }
    } catch (error) {
      console.error('Failed to create lesson:', error);
      toastr.error('Error', 'Failed to create new lesson');
    }
  };

  const handleAddLessonAfter = async (): Promise<void> => {
    if (!week || !courseId) return;
    
    // Check if the current lesson is empty
    if (!lesson.description || lesson.description.trim() === '') {
      toastr.error('Error', 'Cannot add a lesson after an empty lesson. Please add content to the current lesson first.');
      return;
    }
    
    // Generate a unique ID based on current lesson ID and position
    const uniqueId = generateHash(`${lessonId}-after-${Date.now()}`);
    
    // Create a new lesson with default values
    const newLesson: Partial<LessonType> = {
      name: 'New Lesson',
      description: '# New Lesson\n\nAdd your content here.',
      resources: [],
      interviewQuestions: [],
      weekId: weekId,
      tags: [],
      types: [],
      timecodes: [],
      images: [],
      type: 'text', // Required field
      id: uniqueId, // Use the generated hash as ID
      order: lesson.order ? lesson.order + 0.5 : 1 // Position after current lesson
    };
    
    try {
      // First create the lesson on the server
      const result = await dispatch(createLesson(newLesson as LessonType));
      
      if (result && typeof result === 'object' && 'lesson' in result && result.lesson && week) {
        const newLessonData = result.lesson as LessonType;
        
        // Then update the Redux store with the new lesson
        dispatch({
          type: 'courses/createLesson',
          payload: {
            courseId,
            week,
            data: newLessonData
          }
        });
        
        // Invalidate the Redis cache to ensure the new lesson is visible
        try {
          await fetch('/api/v1/courses/invalidate-cache', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'same-origin' // Include cookies for authentication
          });
        } catch (cacheError) {
          console.error('Failed to invalidate cache:', cacheError);
          // Continue even if cache invalidation fails
        }
        
        // Reload the week to get the updated lessons
        dispatch(loadLessons(week));
        
        // Show success notification
        toastr.success('Lesson Created', 'New lesson has been added after the current lesson');
        
        // Ask if user wants to navigate to the new lesson
        toastr.confirm('Would you like to navigate to the new lesson?', {
          onOk: () => {
            if (newLessonData?._id) {
              navigate(`/${getLanguage()}/course/${courseId}/${weekId}/${newLessonData._id}`);
            }
          }
        });
      }
    } catch (error) {
      console.error('Failed to create lesson:', error);
      toastr.error('Error', 'Failed to create new lesson');
    }
  };

  if (!lesson?.name) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  const lessonRating = lesson.rating || {} as LessonRating;

  return (
    <div className="relative min-h-screen bg-white dark:bg-gray-900">
      <Head title={`Skillcrucial - ${adminEditMode ? title : lesson.name}`} />
      
      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 md:px-8 py-12 md:py-16">
        <div className="space-y-12">
          <header>
            <Breadcrumb 
              courseId={courseId} 
              weekId={weekId} 
              courseName={courseName} 
              weekName={week?.name || ''} 
              lessonName={adminEditMode ? title : lesson.name}
              onDetailsClick={() => setIsDetailsModalOpen(true)}
            />
            
            <h1 className="mt-6 text-2xl font-medium text-gray-900 dark:text-white">
              {adminEditMode ? title : lesson.name}
            </h1>

            <div className="flex flex-wrap items-center gap-3 mt-2">
              {interviewQuestions.length > 0 && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300">
                  <svg className="w-3.5 h-3.5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <title>Questions</title>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  {interviewQuestions.length} {interviewQuestions.length === 1 ? 'Question' : 'Questions'}
                </span>
              )}
              
              {lesson.countOfViews !== undefined && lesson.countOfViews > 0 && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                  <svg className="w-3.5 h-3.5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <title>Views</title>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  {lesson.countOfViews} {lesson.countOfViews === 1 ? 'View' : 'Views'}
                </span>
              )}
              
              {lesson.countOfQuestions !== undefined && lesson.countOfQuestions > 0 && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300">
                  <svg className="w-3.5 h-3.5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <title>Questions</title>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                  </svg>
                  {lesson.countOfQuestions} {lesson.countOfQuestions === 1 ? 'Question' : 'Questions'}
                </span>
              )}
              
              {lesson.tags?.length > 0 && (
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {lesson.tags.map((tag: string) => `#${tag}`).join(' ')}
                </div>
              )}
            </div>
          </header>

          {lesson.link && lesson.type && (
            <div>
              <VideoPlayer lesson={{ type: lesson.type, link: lesson.link }} />
              {lesson.type === 'vimeo' && (
                <div className="mt-4">
                  <Rating rating={lessonRating} userId={user?.id || ''} lessonId={lesson._id || ''} />
                </div>
              )}
            </div>
          )}

          {['admin', 'moderator'].includes(role) && (
            <AdminControls
              questionsLength={questions.filter(q => !q.toSkip && !q.answer).length}
              adminEditMode={adminEditMode}
              isDescriptionDirty={isDescriptionDirty}
              onToggleEditMode={handleToggleAdminEditMode}
              onSaveDescription={handleSaveDescription}
            />
          )}

          {/* Navigation positioned relative to content */}
          <div className="relative md:mx-[-4rem]">
            <NavigationButtons
              courseId={courseId}
              weekId={weekId}
              lessons={week?.lessons || []}
              currentLessonId={lessonId}
              onNavigate={() => setLoaded(false)}
              onSaveBeforeNavigate={isDescriptionDirty ? handleSaveDescription : undefined}
              hasUnsavedChanges={isDescriptionDirty}
            />
          </div>

          <LessonContent
            adminEditMode={adminEditMode}
            description={description}
            onSetDescription={handleSetDescription}
            lessonId={lesson._id}
            title={title}
            onSetTitle={handleSetTitle}
          />

          {adminEditMode ? (
            <ResourceEditor
              resources={resources}
              onAddResource={handleAddResource}
              onRemoveResource={handleRemoveResource}
            />
          ) : lesson.resources?.length > 0 && (
            <ResourcesList resources={lesson.resources} />
          )}

          {adminEditMode && (
            <InterviewQuestionsEditor
              questions={interviewQuestions}
              onAddQuestion={handleAddQuestion}
              onAddQuestions={handleAddQuestions}
              onRemoveQuestion={handleRemoveQuestion}
            />
          )}

          {lesson.homeworkId && homework && (
            <Homework
              homeworkId={lesson.homeworkId}
              homework={homework}
              homeworkStatuses={homeworkStatuses}
            />
          )}

          {lessonId && weekId && courseId && (
            <LessonQuestionSection 
              role={role}
              lessonId={lessonId}
              questions={questions}
              weekId={weekId}
              courseId={courseId}
            />
          )}
        </div>
      </div>

      {course && (
        <CourseDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          course={course}
        />
      )}

      {/* Floating Edit/Save Button and Admin Actions */}
      {['admin', 'moderator'].includes(role) && (
        <>
          <FloatingSaveButton
            isVisible={true}
            onSave={handleSaveDescription}
            isEditing={adminEditMode}
            onToggleEdit={handleToggleAdminEditMode}
          />
          
          {/* Admin Dropdown for Adding Lessons */}
          <AdminDropdown 
            onAddLessonBefore={handleAddLessonBefore} 
            onAddLessonAfter={handleAddLessonAfter} 
          />
        </>
      )}
    </div>
  );
};

// Admin Dropdown Component
interface AdminDropdownProps {
  onAddLessonBefore?: () => void;
  onAddLessonAfter?: () => void;
}

const AdminDropdown = ({ onAddLessonBefore, onAddLessonAfter }: AdminDropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array since we only want to run this once

  return (
    <div className="fixed bottom-20 right-20 z-40" ref={dropdownRef}>
      <div className="relative">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-center w-12 h-12 bg-indigo-600 hover:bg-indigo-500 text-white rounded-full shadow-lg transition-colors"
          aria-label="Add lesson"
          title="Add lesson"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
        
        {isOpen && (
          <div className="absolute right-0 bottom-full mb-2 w-40 bg-white dark:bg-gray-800 rounded shadow-lg">
            <button
              type="button"
              onClick={() => {
                if (onAddLessonBefore) onAddLessonBefore();
                setIsOpen(false);
              }}
              className="block w-full text-left px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              Add Lesson Before
            </button>
            
            <button
              type="button"
              onClick={() => {
                if (onAddLessonAfter) onAddLessonAfter();
                setIsOpen(false);
              }}
              className="block w-full text-left px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              Add Lesson After
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Lesson;
