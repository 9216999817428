import { useState } from 'react'
import { useAppDispatch } from '@data'
import { sendJsTask, resetCode } from '@data/reducers/courses'
import type { HomeworkData, TaskResultMap } from '../../interfaces/homework'
import JSTasks from './homework/js-tasks'
import WebTasks from './homework/web-tasks'
import FullscreenEditor from './homework/FullscreenEditor'

interface HomeworkProps {
  homework?: HomeworkData
  homeworkStatuses: TaskResultMap
  homeworkId: string
}

const Homework: React.FC<HomeworkProps> = ({ homework, homeworkStatuses, homeworkId }) => {
  const dispatch = useAppDispatch()
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [currentTaskId, setCurrentTaskId] = useState<string | null>(null)

  if (!homework) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="animate-pulse text-gray-600 dark:text-gray-400">Loading homework...</div>
      </div>
    )
  }

  const jsTasks = [...(homework.jsTasks ?? [])].sort((a, b) => {
    const orderA = a.order ?? 0
    const orderB = b.order ?? 0
    return orderA - orderB
  })

  const webTasks = [...(homework.webTasks ?? [])].sort((a, b) => {
    const orderA = a.order ?? 0
    const orderB = b.order ?? 0
    return orderA - orderB
  })

  const handleFullscreenToggle = (taskId: string) => {
    setCurrentTaskId(taskId)
    setIsFullscreen(true)
  }

  const handleExitFullscreen = () => {
    if (currentTaskId) {
      const taskElement = document.querySelector(`[data-task-id="${currentTaskId}"]`)
      if (taskElement) {
        setTimeout(() => {
          taskElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 100)
      }
    }
    setCurrentTaskId(null)
    setIsFullscreen(false)
  }

  // Find the current task and its neighbors
  const currentTaskIndex = currentTaskId ? jsTasks.findIndex(task => task.id === currentTaskId) : -1
  const currentTask = currentTaskIndex !== -1 ? jsTasks[currentTaskIndex] : null
  const prevTask = currentTaskIndex > 0 ? jsTasks[currentTaskIndex - 1] : null
  const nextTask = currentTaskIndex < jsTasks.length - 1 ? jsTasks[currentTaskIndex + 1] : null

  // Navigation handlers
  const handlePrevTask = () => {
    if (prevTask?.id) {
      setCurrentTaskId(prevTask.id)
    }
  }

  const handleNextTask = () => {
    if (nextTask?.id) {
      setCurrentTaskId(nextTask.id)
    }
  }

  const handleCodeChange = (taskId: string | undefined, newCode: string) => {
    if (!taskId || !homework.id) return
    sessionStorage?.setItem(`${homework.id} - ${taskId}`, newCode)
  }

  const handleReset = (taskId: string | undefined, defaultCode: string | undefined) => {
    if (!taskId || !homework.id || !defaultCode) return
    sessionStorage?.removeItem(`${homework.id} - ${taskId}`)
    dispatch(resetCode({
      homeworkId: homework.id,
      taskId,
      code: defaultCode
    }))
  }

  const handleSubmit = (taskId: string | undefined, submittedCode: string) => {
    if (!taskId || !homework.id) return
    sessionStorage?.setItem(`${homework.id} - ${taskId}`, submittedCode)
    dispatch(sendJsTask(homework.id, taskId, submittedCode))
  }

  return (
    <div className="max-w-4xl mx-auto">
      {!isFullscreen && (
        <>
          <div className="border-l-4 border-yellow-500 p-6 mb-6">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                  <title>Warning</title>
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-4">
                <p className="text-base font-semibold text-gray-900 dark:text-gray-100">
                  Homework submission is temporarily not working. We are working on fixing this issue. Please check back later.
                </p>
              </div>
            </div>
          </div>

          <div className="pb-5 border-b border-gray-200 dark:border-gray-700 mb-6">
            <h3 className="text-xl leading-6 font-medium text-teal-700 dark:text-teal-500">
              Homework
            </h3>
          </div>

          {(homework.type === 'javascript' || homework.type === 'jest') && (
            <JSTasks
              tasks={jsTasks}
              results={homeworkStatuses}
              testDataId={homework.id}
              homeworkId={homeworkId}
              onFullscreenToggle={handleFullscreenToggle}
            />
          )}

          {homework.type === 'web' && (
            <WebTasks
              tasks={webTasks}
              results={homeworkStatuses}
              testDataId={homework.id}
              homeworkId={homeworkId}
            />
          )}
        </>
      )}

      {isFullscreen && currentTask && currentTask.id && (
        <div className="fixed inset-0 z-[55] bg-white dark:bg-gray-900">
          <FullscreenEditor
            task={currentTask}
            code={homeworkStatuses[currentTask.id]?.code || currentTask.code || ''}
            homeworkCode={homeworkStatuses[currentTask.id]?.code || ''}
            testDataId={homework.id}
            result={homeworkStatuses[currentTask.id]?.result}
            homeworkId={homeworkId}
            onPrevTask={prevTask?.id ? handlePrevTask : undefined}
            onNextTask={nextTask?.id ? handleNextTask : undefined}
            onCodeChange={(newCode) => handleCodeChange(currentTask.id, newCode)}
            onReset={() => handleReset(currentTask.id, currentTask.code)}
            onSubmit={(submittedCode) => handleSubmit(currentTask.id, submittedCode)}
            onExitFullscreen={handleExitFullscreen}
          />
        </div>
      )}
    </div>
  )
}

export default Homework
