import classNames from 'classnames'

interface TextQuestionProps {
  questionId: string
  isIncorrect: boolean
  value: string
  onChange: (questionId: string, value: string) => void
}

const TextQuestion = ({ questionId, isIncorrect, value, onChange }: TextQuestionProps) => {
  return (
    <input
      className={classNames(
        'w-full px-5 py-3 rounded-lg border bg-gray-900/80 text-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-300 text-base',
        {
          'border-red-500/50 focus:border-red-500 focus:ring-red-500 shadow-lg shadow-red-500/10': isIncorrect,
          'border-gray-700 focus:border-teal-500 focus:ring-teal-500 shadow-lg shadow-teal-500/5': !isIncorrect
        }
      )}
      placeholder="Type your answer here..."
      value={value || ''}
      onChange={(e) => onChange(questionId, e.target.value)}
    />
  )
}

export default TextQuestion
