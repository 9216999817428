import { Link } from 'react-router-dom'
import { getLanguage } from '../../../config/i18n'
import type { WeekData } from './types'

interface SuccessBannerProps {
  courseId?: string
  week?: WeekData
}

const SuccessBanner = ({ courseId, week }: SuccessBannerProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 rounded-lg p-8 max-w-lg w-full mx-4 text-center border-2 border-teal-500 shadow-2xl animate-bounce-once">
        <div className="text-4xl mb-4">🎉 🌟 🚀</div>
        <h2 className="text-3xl font-bold text-teal-400 mb-2">You're Awesome!</h2>
        <p className="text-xl text-gray-200 mb-2">
          You've crushed this interview like a pro!
        </p>
        <p className="text-gray-400 mb-8 text-lg">
          Time to celebrate with a victory dance! 💃 🕺
        </p>
        <Link
          to={`/${getLanguage()}/course/${courseId}/${week?.weekId}`}
          className="inline-flex items-center px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white font-medium rounded-lg transition-colors duration-200 group"
        >
          <span className="group-hover:scale-110 transition-transform duration-200">
            🎯 Back to {week?.name || 'Week'} 🎯
          </span>
        </Link>
      </div>
    </div>
  )
}

export default SuccessBanner
