import * as React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getLanguage } from '../../../../config/i18n'
import { useAppSelector } from '@data'

const Hero: React.FC = () => {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = React.useState(false)
  const {user} = useAppSelector((s) => s.authentication)

  return (
    <section className="min-h-screen hero relative bg-black flex flex-col justify-center items-center py-16">
      <div className="container mx-auto z-50 text-center px-4">
        <h1 className="text-3xl font-bold text-white mb-8 tracking-wide">
        Become a Software Developer with <span className="text-teal-500">Skillcrucial</span> 
        </h1>

        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
          {!user.email && (
            <Link
              className="bg-transparent text-white border-2 border-white hover:bg-white hover:text-black transition-all duration-300 rounded-lg py-3 px-8 text-lg font-medium flex items-center justify-center"
              to={`/${getLanguage()}/courses`}
            >
              {t('default.seeCourses')} <i className="fa fa-arrow-right ml-3" />
            </Link>
          )}

          {user.email && (
            <Link
              className="bg-transparent text-white border-2 border-white hover:bg-white hover:text-black transition-all duration-300 rounded-lg py-3 px-8 text-lg font-medium flex items-center justify-center"
              to={`/${getLanguage()}/dashboard`}
            >
              {t('default.myCourses')}
            </Link>
          )}
        </div>
      </div>
      <div 
        className="absolute inset-0 z-0 bg-cover bg-center opacity-20" 
        style={{backgroundImage: "url('/images/landing/hero.jpg')"}} 
      />
      
      <a
        className={`fixed right-0 cursor-pointer top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-l-md transition-all duration-300 flex items-center overflow-hidden ${isHovered ? 'w-60' : 'w-16'}`}
        href="https://www.comebackalive.in.ua/donate"
        rel="noreferrer"
        target="_blank"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img 
          src="/images/ukraine-flag-icon.svg" 
          alt="Ukrainian Flag" 
          className="w-12 h-12 mr-4 flex-shrink-0"
        />
        <span className={`whitespace-nowrap text-white transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
          Support Ukraine
        </span>
      </a>
    </section>
  )
}

export default Hero
