import classNames from 'classnames'
import type { HomeWorkQuestions } from '../../../../interfaces/mongoose.gen'

interface SingleQuestionProps {
  questionId: string
  options: HomeWorkQuestions['options']
  selectedValue: string
  onChange: (questionId: string, value: string) => void
}

const SingleQuestion = ({ questionId, options, selectedValue, onChange }: SingleQuestionProps) => {
  return (
    <div className="flex flex-wrap gap-3">
      {options.map((option) => {
        const optionId = option._id
        const optionText = option.text

        if (!optionId || typeof optionText !== 'string') return null

        return (
          <button
            key={optionId}
            type="button"
            onClick={() => onChange(questionId, optionText)}
            className={classNames(
              'px-5 py-3 rounded-lg font-medium transition-all duration-300 text-base shadow-lg',
              {
                'bg-teal-600 text-white shadow-teal-500/20 hover:bg-teal-700 transform hover:-translate-y-1':
                  selectedValue === optionText,
                'bg-gray-800 text-gray-300 hover:bg-gray-700 border border-gray-700 hover:border-teal-500/30 transform hover:-translate-y-1':
                  selectedValue !== optionText
              }
            )}
          >
            {optionText}
          </button>
        )
      })}
    </div>
  )
}

export default SingleQuestion
