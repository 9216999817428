import React from 'react'
import Playground from 'javascript-playgrounds'
import type { PlaygroundWrapperProps, CustomCSSProperties } from './types'

const calculateHeight = (code: string): number => {
  const lineCount = code.split('\n').length;
  const lineHeight = 22; // Approximate height per line in pixels
  return (lineCount + 5) * lineHeight; // Add 5 extra lines
}

export const PlaygroundWrapper: React.FC<PlaygroundWrapperProps> = ({ code, type }) => {
  const minHeight = calculateHeight(code);

  return (
    <div className="my-4">
      <div className="dark:invert" style={{ minHeight }}>
        <Playground
          code={code.trim()}
          preset={type}
          className="w-full h-full"
          style={{
            '--bg-color': '#ffffff',
            '--fg-color': '#000000',
            '--editor-bg-color': '#ffffff',
            '--editor-fg-color': '#000000',
            '--syntax-keyword-color': '#007bff',
            '--syntax-string-color': '#28a745',
            '--syntax-comment-color': '#6c757d',
          } as CustomCSSProperties}
        />
      </div>
    </div>
  );
};
