import type { ReactElement } from 'react'
import type { SpringValue } from '@react-spring/web'
import { animated, useTransition } from '@react-spring/web'
import { useAppSelector } from '@data'
import Loader from '../common/loader'
import Head from '../head'
import CourseCard from './CourseCard'
import CourseDisclaimer from './CourseDisclaimer'

interface StoreCourse {
  courseId: string;
  name: string;
  picture?: string;
  startDate: Date;
  endDate: Date;
  isEnrolled?: boolean;
  description?: string;
  slug?: string;
  weeks?: unknown[];
  relatedCourses?: string[];
}

interface SectionHeaderProps {
  icon: ReactElement;
  title: string;
}

interface AnimatedDivProps {
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
}

const SectionHeader = ({ icon, title }: SectionHeaderProps): ReactElement => (
  <div className="flex items-center gap-3 mb-6">
    <div className="text-gray-400 dark:text-gray-500">
      {icon}
    </div>
    <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
      {title}
    </h2>
  </div>
)

const BookmarkIcon = (): ReactElement => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
  </svg>
)

const SchoolIcon = (): ReactElement => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 14l9-5-9-5-9 5 9 5z M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998a12.078 12.078 0 01.665-6.479L12 14z M12 14l-6.16-3.422a12.083 12.083 0 00-.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 016.824-2.998a12.078 12.078 0 00-.665-6.479L12 14z" />
  </svg>
)

const LockIcon = (): ReactElement => (
  <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8V7a4 4 0 00-8 0v4h8z" />
  </svg>
)

const CourseList = (): ReactElement => {
  const isRequesting = useAppSelector((s) => s.courses.isRequesting)
  const isInitialRequestDone = useAppSelector((s) => s.courses.isInitialRequestDone)
  const role = useAppSelector((s) => s.authentication.user.role)
  const list = useAppSelector((state) => state.courses.list) as StoreCourse[]
  const chargePlans = useAppSelector((s) => s.authentication.user.chargePlans)
  const isAdmin = role === 'admin'
  const hasActiveCourse = chargePlans?.some((plan) => plan.isActive) || false

  const transitions = useTransition(list, {
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },  
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, -10px, 0)' },
    config: { tension: 220, friction: 24 },
    keys: (item) => item.courseId
  })

  if (isRequesting && !isInitialRequestDone) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader />
      </div>
    )
  }

  const enrolledCourses = list.filter(course => course.isEnrolled).map(course => ({
    courseId: course.courseId,
    name: course.name,
    picture: course.picture,
    startDate: course.startDate,
    endDate: course.endDate,
    isEnrolled: course.isEnrolled
  }))

  const activeCourses = list.filter(course => !course.isEnrolled).map(course => ({
    courseId: course.courseId,
    name: course.name,
    picture: course.picture,
    startDate: course.startDate,
    endDate: course.endDate,
    isEnrolled: course.isEnrolled
  }))
  
  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      <Head title="My Courses" />

      {/* {hasActiveCourse && <CourseDisclaimer />} */}

      {enrolledCourses.length > 0 && (
        <div className="mb-12">
          <SectionHeader 
            icon={<BookmarkIcon />}
            title="Enrolled Courses"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {enrolledCourses.map((course) => (
              <div key={course.courseId} className="h-full">
                <CourseCard course={course} isAdmin={isAdmin} /> 
              </div>
            ))}
          </div>
        </div>
      )}

      {activeCourses.length > 0 && (
        <div>
          <SectionHeader 
            icon={<SchoolIcon />}
            title="Active Courses"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {activeCourses.map((course) => (
              <div key={course.courseId} className="h-full">
                <CourseCard course={course} isAdmin={isAdmin} /> 
              </div>
            ))}
          </div>
        </div>
      )}

      {list.length === 0 && (
        <div className="flex flex-col items-center justify-center min-h-[400px] text-center">
          <div className="w-14 h-14 mb-5 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
            <LockIcon />
          </div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
            No Course Access
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md">
            You don't have access to any courses at the moment. Please contact support or check your subscription status.
          </p>
        </div>
      )}
    </div>
  )
}

export default CourseList
