import { type FC, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { submitQuestion, sendAnswer, skipAnswer } from '@data/reducers/courses';
import { useAppDispatch } from '@data';
import { Editor } from '../common/text-editor/editor';
import UserAvatar from '../admin/users/components/UserAvatar';
import type { LessonFeedback } from '../../interfaces/mongoose.gen';

interface LessonQuestionSectionProps {
  questions: LessonFeedback[];
  role: string;
  lessonId: string;
  courseId: string;
  weekId: string;
}

const LessonQuestionSection: FC<LessonQuestionSectionProps> = ({
  questions,
  role,
  lessonId,
  courseId,
  weekId
}) => {
  const dispatch = useAppDispatch();
  const [isQuestionViewOpen, setIsQuestionViewOpen] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [sentQuestions, setSentQuestions] = useState<Record<string, boolean>>({});

  const toggleQuestionView = () => setIsQuestionViewOpen(!isQuestionViewOpen);

  const handleAnswerChange = (questionId: string) => (value: string) => {
    dispatch(sendAnswer({ lessonId, qId: questionId, answer: value }));
  };

  const handleSubmitAnswer = (questionId: string, answer: string) => {
    dispatch(sendAnswer({ lessonId, qId: questionId, answer }));
  };

  const handleSkipQuestion = (questionId: string) => {
    dispatch(skipAnswer({ lessonId, qId: questionId }));
  };

  const handleSubmitNewQuestion = () => {
    dispatch(submitQuestion({ question: newQuestion, lessonId }));
    toggleQuestionView();
    setSentQuestions({ ...sentQuestions, [lessonId]: true });
  };

  const renderQuestionItem = (question: LessonFeedback) => (
    <div key={question.question} className="flex space-x-3 mt-6 pb-6 border-b border-gray-700 dark:border-gray-600 rounded">
      <UserAvatar 
        user={{ 
          stackOverflow: { profile_image: question?.user?.profileImage },
          name: question?.user?.name 
        }}
        size="sm"
      />
      <div className="flex flex-col w-full">
        <UserInfo role={role} user={question.user} />
        <QuestionContent question={question} />
        <AnswerSection
          role={role}
          question={question}
          onAnswerChange={handleAnswerChange}
          onSubmitAnswer={handleSubmitAnswer}
          onSkipQuestion={handleSkipQuestion}
        />
      </div>
    </div>
  );

  return (
    <div>
      <QuestionList questions={questions} renderItem={renderQuestionItem} />
      <AskQuestionButton onClick={toggleQuestionView} />
      {isQuestionViewOpen && (
        <NewQuestionForm
          lessonId={lessonId}
          newQuestion={newQuestion}
          sentQuestions={sentQuestions}
          onQuestionChange={setNewQuestion}
          onSubmit={handleSubmitNewQuestion}
        />
      )}
    </div>
  );
};

const QuestionList: FC<{ questions: LessonFeedback[]; renderItem: (question: LessonFeedback) => React.ReactNode }> = ({ questions, renderItem }) => (
  <div className="w-full">
    {questions.length > 0 && (
      <h2 className="mb-4 mt-8 p-2 border dark:border-gray-700 shadow-lg text-lg inline-block rounded dark:bg-[#222] bg-white dark:text-gray-200">
        Q & A:
      </h2>
    )}
    {questions.map(renderItem)}
  </div>
);

const UserInfo: FC<{ role: string; user: LessonFeedback['user'] }> = ({ role, user }) => (
  <div className="text-sm">
    {role === 'admin' && user ? (
      <Link to={`/en/admin/users/${user.id}`} className="font-medium text-gray-900 dark:text-gray-100">
        {user.name}
      </Link>
    ) : (
      <div className="font-medium text-gray-900 dark:text-gray-100">{user?.name}</div>
    )}
  </div>
);

const QuestionContent: FC<{ question: LessonFeedback }> = ({ question }) => (
  <div className="mt-1 text-sm prose prose-md prose-slate dark:prose-invert">
    <p className="dark:text-gray-300">{question.question}</p>
    {question.answer ? (
      <div className="shadow-md rounded mt-2 px-3 py-1 bg-slate-100 dark:bg-[#222]">
        <ReactMarkdown>{question.answer}</ReactMarkdown>
      </div>
    ) : (
      <p className="dark:text-gray-400">To Be Responded</p>
    )}
  </div>
);

const AnswerSection: FC<{
  role: string;
  question: LessonFeedback;
  onAnswerChange: (questionId: string) => (value: string) => void;
  onSubmitAnswer: (questionId: string, answer: string) => void;
  onSkipQuestion: (questionId: string) => void;
}> = ({ role, question, onAnswerChange, onSubmitAnswer, onSkipQuestion }) => {
  if (role !== 'admin' || !question._id) return null;

  const questionId = question._id;

  return (
    <div>
      {question.toSkip && <h2 className="dark:text-gray-300">SKIPPED</h2>}
      <Editor
        value={question.answer ?? ''}
        onChange={onAnswerChange(questionId)}
      />
      <div className="flex justify-between mt-2">
        <button
          type="button"
          className="bg-teal-600 hover:bg-teal-500 dark:bg-teal-700 dark:hover:bg-teal-600 text-white font-bold py-2 px-4 rounded transition-colors"
          onClick={() => onSubmitAnswer(questionId, question.answer ?? '')}
        >
          Send Answer
        </button>
        <button
          type="button"
          className="bg-teal-600 hover:bg-teal-500 dark:bg-teal-700 dark:hover:bg-teal-600 text-white font-bold py-2 px-4 rounded transition-colors"
          onClick={() => onSkipQuestion(questionId)}
        >
          Skip
        </button>
      </div>
    </div>
  );
};

const AskQuestionButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className="flex my-2">
    <div className="w-full p-2">
      <button
        type="button"
        className="bg-teal-600 hover:bg-teal-500 dark:bg-teal-700 dark:hover:bg-teal-600 text-white font-bold py-2 px-4 rounded float-right transition-colors"
        onClick={onClick}
      >
        Ask Question
      </button>
    </div>
  </div>
);

const NewQuestionForm: FC<{
  lessonId: string;
  newQuestion: string;
  sentQuestions: Record<string, boolean>;
  onQuestionChange: (value: string) => void;
  onSubmit: () => void;
}> = ({ lessonId, newQuestion, sentQuestions, onQuestionChange, onSubmit }) => (
  <div>
    <div className="prose prose-sm max-w-none flex my-2 p-2 dark:prose-invert">
      <div className="w-full">
        {lessonId && sentQuestions[lessonId] ? (
          <span className="dark:text-gray-300">Your question has been sent to the server</span>
        ) : (
          <Editor value={newQuestion} onChange={onQuestionChange} />
        )}
      </div>
    </div>
    <div className="flex my-2 p-2">
      <div className="w-full flex justify-end">
        <button
          type="button"
          className="bg-teal-600 hover:bg-teal-500 dark:bg-teal-700 dark:hover:bg-teal-600 text-white font-bold py-2 px-4 rounded transition-colors"
          onClick={onSubmit}
        >
          Submit Question
        </button>
      </div>
    </div>
  </div>
);

export default LessonQuestionSection;
