import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getLanguage, formatDate } from '../../config/i18n';
import { SuccessModal } from './enroll/success-modal';

interface CourseType {
  id: string;
  name: string;
  registrationAllowed?: boolean;
  isDisabled?: boolean;
  hasDetails?: boolean;
  image: string;
  lngNs: string;
  date: Date;
  startDate: string;
  endDate: string;
  price: {
    default: number;
    uk: number;
    ru: number;
    en: number;
  };
  isEnrolled?: boolean;
}

interface Props {
  course: CourseType;
  toggleModal: (courseId: string, registrationAllowed: boolean) => () => void;
  userRegistrationDate?: Date;
  isEnrolled?: boolean;
}

export function CourseCard({ course, toggleModal, userRegistrationDate, isEnrolled }: Props) {
  const { t: courseT } = useTranslation(`courses/${course.lngNs}`);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);

  const courseData = {
    id: course.id,
    name: courseT('name'),
    startDate: new Date(course.startDate),
    endDate: new Date(course.endDate),
    lang: courseT('language'),
    description: courseT('description', ''),
    duration: courseT('duration', '') || '1 year',
    message: courseT('callToAction.button', 'Enroll Now'),
    image: course.image,
    alt: courseT('name'),
    details: courseT('details', 'Learn More'),
    isDisabled: course.isDisabled,
    registrationAllowed: course.registrationAllowed ?? false,
    hasDetails: course.hasDetails,
    price: course.price[getLanguage() as keyof typeof course.price] || course.price.default
  };

  const isPassed = courseData.endDate < new Date();
  const isFree = courseData.price === 0;
  const isAutoEnrollEligible = userRegistrationDate && new Date(userRegistrationDate) < new Date('2024-12-01');
  const buttonDisabled = courseData.isDisabled || isPassed || isEnrolling || isEnrolled;

  const handleEnrollClick = async () => {
    if (isEnrolled) return;
    
    if (isAutoEnrollEligible && !isPassed) {
      try {
        setIsEnrolling(true);
        const response = await axios.post(`/api/v1/courses/${courseData.id}/enroll`);
        if (response.status === 201) {
          setShowSuccessModal(true);
        } else {
          toggleModal(courseData.id, courseData.registrationAllowed)();
        }
      } catch (error) {
        console.error('Failed to enroll:', error);
        toggleModal(courseData.id, courseData.registrationAllowed)();
      } finally {
        setIsEnrolling(false);
      }
    } else {
      toggleModal(courseData.id, courseData.registrationAllowed)();
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300 flex flex-col h-full">
      <div className="h-[180px] relative">
        <img
          loading="lazy"
          src={courseData.image}
          alt={courseData.alt}
          onLoad={() => setImageLoaded(true)}
          className={`w-full h-full object-cover ${!imageLoaded && 'opacity-0'}`}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
        
        <div className="absolute top-2 left-2">
          <span className="inline-block px-2 py-0.5 text-xs font-medium bg-white/90 text-gray-900 rounded-full">
            {courseData.lang}
          </span>
        </div>
      </div>

      <div className="flex flex-col flex-grow p-4">
        <div className="flex-grow">
          <h2 className="text-base font-bold text-gray-900 dark:text-white mb-1.5">
            {courseData.name}
          </h2>

          <p className="text-gray-600 dark:text-gray-300 text-xs mb-3 line-clamp-2">
            {courseData.description}
          </p>

          <div className="flex items-center justify-between text-xs text-gray-500 dark:text-gray-400">
            <div className="flex items-center gap-1">
              <i className="miu-icon-timer text-sm" />
              <span>{courseData.duration}</span>
            </div>
            <div className="flex items-center gap-1">
              <i className="miu-icon-calendar_today text-sm" />
              <span>{formatDate(courseData.startDate)}</span>
            </div>
            {!isFree && (
              <div className="flex items-center gap-1">
                <i className="miu-icon-attach_money text-sm" />
                <span>${courseData.price}/{t('courses.common.month')}</span>
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-2 mt-4 pt-3 border-t border-gray-100 dark:border-gray-700">
          {courseData.hasDetails && !isEnrolled && (
            <Link
              to={`/${getLanguage()}/courses/${courseData.id}`}
              className="flex-1 px-3 py-1.5 text-xs font-medium text-center rounded-lg text-teal-700 dark:text-teal-400 hover:bg-teal-50 dark:hover:bg-teal-900/20 transition-colors"
            >
              {courseData.details}
            </Link>
          )}
          <button
            type="button"
            onClick={handleEnrollClick}
            disabled={buttonDisabled}
            className={`flex-1 px-3 py-1.5 text-xs font-medium rounded-lg transition-colors ${
              buttonDisabled
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                : isEnrolled
                  ? 'bg-yellow-500 text-white'
                  : 'bg-primary text-white hover:bg-primary-dark'
            }`}
          >
            {isEnrolled
              ? t('courses.status.enrolled')
              : isEnrolling
                ? t('courses.common.enrolling')
                : isPassed
                  ? t('courses.status.passed')
                  : courseData.message}
          </button>
        </div>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        courseName={courseData.name}
      />
    </div>
  );
}
