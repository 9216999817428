import type { HomeworkData, TaskResultMap } from '../../interfaces/homework'
import type { RootState } from '..'
import type { HomeworkJsTasks, HomeworkWebTasks } from '../../interfaces/mongoose.gen'

interface StoreHomeworkStatus {
  code?: string
  url?: string
  timestamp?: number
  result?: {
    status: string
    messages: { message: string }[]
    details?: string[]
    error?: string
  }
}

type HomeworkType = 'javascript' | 'web' | 'jest'

interface StoreHomework {
  id?: string
  name: string
  type?: HomeworkType
  tags: string[]
  jsTasks: HomeworkJsTasks[]
  webTasks: HomeworkWebTasks[]
}

export const selectHomeworkData = (state: RootState, homeworkId: string): HomeworkData | undefined => {
  const homework = state.courses.homeworks[homeworkId] as unknown as StoreHomework | undefined
  if (!homework) return undefined

  return {
    id: homework.id || '',
    type: (homework.type || 'javascript') as HomeworkType,
    jsTasks: homework.jsTasks || [],
    webTasks: homework.webTasks || []
  }
}

export const selectHomeworkStatuses = (state: RootState, homeworkId: string): TaskResultMap => {
  const statuses: Record<string, StoreHomeworkStatus> = state.courses.homeworkStatuses[homeworkId] || {}
  
  return Object.entries(statuses).reduce((acc: TaskResultMap, [taskId, status]) => {
    return Object.assign(acc, {
      [taskId]: {
        result: {
          status: (status.result?.status || 'pending') as 'ok' | 'error' | 'accepted',
          messages: (status.result?.messages || []).map(msg => ({ message: String(msg) })),
          details: status.result?.details || [],
          error: status.result?.error ?? '',
        },
        code: status.code,
        url: status.url
      }
    })
  }, {})
}
