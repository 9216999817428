import axios from 'axios';
import type { InternalAxiosRequestConfig, AxiosError } from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const api = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // For verify endpoint, send the token in the request body
  if (config.url === '/authentication/verify') {
    const token = cookies.get('IACCESS') || cookies.get('Access');
    if (token) {
      config.data = { ...config.data, token };
    }
    return config;
  }

  // For impersonation endpoints, use Access token
  if (config.url?.includes('/authentication/impersonate') || config.url?.includes('/authentication/stop-impersonating')) {
    const token = cookies.get('Access')?.trim();
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  // For all other requests, prefer IACCESS token if it exists
  const iAccessToken = cookies.get('IACCESS')?.trim();
  const accessToken = cookies.get('Access')?.trim();
  
  if (iAccessToken || accessToken) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${iAccessToken || accessToken}`;
  }
  
  return config;
}, (error: AxiosError) => {
  return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      cookies.remove('Access', { path: '/' });
      cookies.remove('IACCESS', { path: '/' });
    }
    return Promise.reject(error);
  }
);

export default api;
