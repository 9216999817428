import { useState, useEffect } from 'react';
import type { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@data';
import type { InterviewQuestion, InterviewQuestionOption } from '../../interfaces/interview';
import type { Lesson as LessonType } from '../../interfaces/mongoose.gen';
import { Editor } from '../common/text-editor/editor';
import JsonUploader from './json-uploader';

interface InterviewQuestionsEditorProps {
  questions: InterviewQuestion[];
  onAddQuestion: (question: InterviewQuestion) => void;
  onAddQuestions?: (questions: InterviewQuestion[]) => void;
  onRemoveQuestion: (questionId: string) => void;
}

const InterviewQuestionsEditor: FC<InterviewQuestionsEditorProps> = ({
  questions,
  onAddQuestion,
  onAddQuestions,
  onRemoveQuestion
}) => {
  const { id: courseId = '', weekId = '', lessonId = '' } = useParams();
  const [questionType, setQuestionType] = useState<'text' | 'single' | 'multiple'>('text');
  const [questionText, setQuestionText] = useState('');
  const [options, setOptions] = useState<InterviewQuestionOption[]>([]);
  const [newOption, setNewOption] = useState('');
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
  const [selectedLessons, setSelectedLessons] = useState<string[]>(lessonId ? [lessonId] : []);
  const [error, setError] = useState('');

  // Get course and week data
  const course = useAppSelector((s) => s.courses.list.find((it) => it?.courseId === courseId));
  const week = useAppSelector((s) => 
    (s.courses.list.find((it) => it?.courseId === courseId)?.weeks ?? []).find(
      (it) => it.weekId === weekId
    )
  );

  // Get all lessons from the current week
  const weekLessons = week?.lessons || [];

  const handleAddOption = () => {
    if (!newOption.trim()) {
      setError('Option text cannot be empty');
      return;
    }

    const newOptionObj: InterviewQuestionOption = {
      _id: `temp-${Date.now()}`,
      text: newOption.trim()
    };

    setOptions([...options, newOptionObj]);
    setNewOption('');
    setError('');
  };

  const handleRemoveOption = (optionId: string) => {
    setOptions(options.filter(option => option._id !== optionId));
    setCorrectAnswers(correctAnswers.filter(id => id !== optionId));
  };

  const handleToggleCorrect = (optionId: string) => {
    if (questionType === 'single') {
      setCorrectAnswers([optionId]);
    } else {
      if (correctAnswers.includes(optionId)) {
        setCorrectAnswers(correctAnswers.filter(id => id !== optionId));
      } else {
        setCorrectAnswers([...correctAnswers, optionId]);
      }
    }
  };

  const handleAddQuestion = () => {
    if (!questionText.trim()) {
      setError('Question text is required');
      return;
    }

    if ((questionType === 'single' || questionType === 'multiple') && options.length < 2) {
      setError('At least two options are required for single/multiple choice questions');
      return;
    }

    if ((questionType === 'single' && correctAnswers.length !== 1) || 
        (questionType === 'multiple' && correctAnswers.length < 1)) {
      setError('Please select the correct answer(s)');
      return;
    }

    if (selectedLessons.length === 0) {
      setError('Please select at least one source lesson');
      return;
    }

    const newQuestion: InterviewQuestion = {
      _id: `temp-${Date.now()}`,
      question: questionText.trim(),
      type: questionType,
      options: questionType !== 'text' ? options : undefined,
      correct: questionType === 'text' ? '' : (questionType === 'single' ? correctAnswers[0] : correctAnswers),
      lessonIds: selectedLessons
    };

    onAddQuestion(newQuestion);
    
    // Reset form
    setQuestionText('');
    setQuestionType('text');
    setOptions([]);
    setCorrectAnswers([]);
    // Keep the selected lessons for the next question
    setError('');
  };

  return (
    <div className="mt-8 border dark:border-gray-700 rounded-lg shadow-lg bg-white dark:bg-[#222] transition-colors overflow-hidden">
      <div className="border-b dark:border-gray-700 px-6 py-4">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 flex items-center gap-2">
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="questions-icon">
            <title id="questions-icon">Interview Questions Section</title>
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
          </svg>
          Edit Interview Questions
        </h3>
      </div>
      
      <div className="p-6">
        {/* Current Questions */}
        {questions.length > 0 && (
          <div className="mb-6">
            <h4 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-3">Current Questions</h4>
            <ul className="space-y-4">
              {questions.map((question) => (
                <li 
                  key={question._id}
                  className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <span className="inline-block px-2 py-1 text-xs font-medium rounded bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 mb-2">
                        {question.type === 'text' ? 'Text Answer' : question.type === 'single' ? 'Single Choice' : 'Multiple Choice'}
                      </span>
                      <p className="text-gray-800 dark:text-gray-200 font-medium">{question.question}</p>
                    </div>
                    <button
                      type="button"
                      onClick={() => onRemoveQuestion(question._id)}
                      className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 transition-colors"
                      aria-label={`Remove question ${question.question}`}
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="delete-question-icon">
                        <title id="delete-question-icon">Delete question</title>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                  
                  {question.options && question.options.length > 0 && (
                    <div className="mt-2 pl-4 border-l-2 border-gray-200 dark:border-gray-700">
                      <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">Options:</p>
                      <ul className="space-y-1">
                        {question.options.map((option) => {
                          const isCorrect = question.type === 'single' 
                            ? question.correct === option._id
                            : Array.isArray(question.correct) && question.correct.includes(option._id);
                          
                          return (
                            <li key={option._id} className="flex items-center">
                              {isCorrect ? (
                                <svg className="w-4 h-4 text-green-500 mr-1" fill="currentColor" viewBox="0 0 20 20" role="img" aria-labelledby="correct-icon">
                                  <title id="correct-icon">Correct answer</title>
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                              ) : (
                                <svg className="w-4 h-4 text-gray-400 mr-1" fill="currentColor" viewBox="0 0 20 20" role="img" aria-labelledby="option-icon">
                                  <title id="option-icon">Answer option</title>
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                                </svg>
                              )}
                              <span className={`text-sm ${isCorrect ? 'font-medium text-gray-800 dark:text-gray-200' : 'text-gray-600 dark:text-gray-400'}`}>
                                {option.text}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Bulk Upload Questions */}
        <div className="mb-8">
          <h4 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-3">Bulk Upload Questions</h4>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
            Enter or upload JSON containing interview questions. The JSON should be an array of question objects.
          </p>
          
          <JsonUploader 
            onUpload={(uploadedQuestions) => {
              if (onAddQuestions) {
                // Add all questions at once if supported
                onAddQuestions(uploadedQuestions);
              } else {
                // Fall back to adding questions one by one
                for (const question of uploadedQuestions) {
                  onAddQuestion(question);
                }
              }
            }} 
          />
        </div>

        {/* Add New Question */}
        <div className="space-y-4 border-t dark:border-gray-700 pt-8">
          <h4 className="text-lg font-medium text-gray-800 dark:text-gray-200">Add New Question</h4>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Question Type
            </label>
            <div className="flex flex-wrap gap-3">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-teal-600"
                  name="questionType"
                  value="text"
                  checked={questionType === 'text'}
                  onChange={() => setQuestionType('text')}
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">Text Answer</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-teal-600"
                  name="questionType"
                  value="single"
                  checked={questionType === 'single'}
                  onChange={() => setQuestionType('single')}
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">Single Choice</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-teal-600"
                  name="questionType"
                  value="multiple"
                  checked={questionType === 'multiple'}
                  onChange={() => setQuestionType('multiple')}
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">Multiple Choice</span>
              </label>
            </div>
          </div>
          
          <div>
            <label htmlFor="question-text" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Question Text
            </label>
            <Editor
              value={questionText}
              onChange={setQuestionText}
            />
          </div>

          {/* Lesson Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Source Lesson(s)
            </label>
            <div className="border border-gray-300 dark:border-gray-600 rounded-md p-3 bg-gray-50 dark:bg-gray-800 max-h-48 overflow-y-auto">
              {weekLessons.length > 0 ? (
                <div className="space-y-2">
                  {weekLessons.map((lesson) => (
                    <label key={lesson.id} className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-teal-600 rounded"
                        checked={selectedLessons.includes(lesson.id || '')}
                        onChange={() => {
                          if (selectedLessons.includes(lesson.id || '')) {
                            setSelectedLessons(selectedLessons.filter(id => id !== lesson.id));
                          } else {
                            setSelectedLessons([...selectedLessons, lesson.id || '']);
                          }
                        }}
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">{lesson.name}</span>
                    </label>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 dark:text-gray-400 text-sm italic">No lessons available in this week</p>
              )}
            </div>
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              Select the lesson(s) this question is related to. This helps students understand where the question comes from.
            </p>
          </div>
          
          {(questionType === 'single' || questionType === 'multiple') && (
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Options
              </label>
              
              {options.length > 0 && (
                <ul className="mb-3 space-y-2">
                  {options.map((option) => (
                    <li key={option._id} className="flex items-center gap-2 p-2 rounded bg-gray-50 dark:bg-gray-800">
                      <input
                        type={questionType === 'single' ? 'radio' : 'checkbox'}
                        checked={correctAnswers.includes(option._id)}
                        onChange={() => handleToggleCorrect(option._id)}
                        className={questionType === 'single' ? 'form-radio text-teal-600' : 'form-checkbox text-teal-600'}
                      />
                      <span className="flex-1 text-gray-700 dark:text-gray-300">{option.text}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveOption(option._id)}
                        className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 transition-colors"
                        aria-label={`Remove option ${option.text}`}
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="delete-option-icon">
                          <title id="delete-option-icon">Delete option</title>
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  placeholder="Enter option text"
                  className="flex-1 px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:text-white"
                />
                <button
                  type="button"
                  onClick={handleAddOption}
                  className="px-3 py-2 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-200 font-medium rounded-md shadow-sm transition-colors"
                >
                  Add Option
                </button>
              </div>
            </div>
          )}
          
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
          
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleAddQuestion}
              className="px-4 py-2 bg-teal-600 hover:bg-teal-500 dark:bg-teal-700 dark:hover:bg-teal-600 text-white font-medium rounded-md shadow-sm transition-colors"
            >
              Add Question
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewQuestionsEditor;
