import { useState, FC } from 'react';
import type { LessonResource } from '../../interfaces/mongoose.gen';

interface ResourceEditorProps {
  resources: LessonResource[];
  onAddResource: (resource: { text: string; link: string }) => void;
  onRemoveResource: (resourceId: string) => void;
}

const ResourceEditor: React.FC<ResourceEditorProps> = ({ 
  resources, 
  onAddResource, 
  onRemoveResource 
}) => {
  const [newResource, setNewResource] = useState({ text: '', link: '' });
  const [error, setError] = useState('');

  const handleAddResource = () => {
    if (!newResource.text.trim() || !newResource.link.trim()) {
      setError('Both text and link are required');
      return;
    }

    // Basic URL validation
    try {
      new URL(newResource.link);
    } catch (e) {
      setError('Please enter a valid URL (include http:// or https://)');
      return;
    }

    onAddResource(newResource);
    setNewResource({ text: '', link: '' });
    setError('');
  };

  return (
    <div className="mt-8 border dark:border-gray-700 rounded-lg shadow-lg bg-white dark:bg-[#222] transition-colors overflow-hidden">
      <div className="border-b dark:border-gray-700 px-6 py-4">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 flex items-center gap-2">
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="resources-icon">
            <title id="resources-icon">Resources Section</title>
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z" />
          </svg>
          Edit Resources
        </h3>
      </div>
      
      <div className="p-6">
        {/* Current Resources */}
        {resources.length > 0 && (
          <div className="mb-6">
            <h4 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-3">Current Resources</h4>
            <ul className="space-y-3">
              {resources.map((resource) => (
                <li 
                  key={resource.id || resource._id}
                  className="group flex items-center gap-3 p-2 rounded-lg bg-gray-50 dark:bg-gray-800"
                >
                  <span className="flex-1 text-gray-700 dark:text-gray-300 font-medium">
                    {resource.text}
                  </span>
                  <span className="text-gray-500 dark:text-gray-400 text-sm truncate max-w-[200px]">
                    {resource.link}
                  </span>
                  <button
                    type="button"
                    onClick={() => onRemoveResource(resource.id || resource._id || '')}
                    className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 transition-colors"
                    aria-label={`Remove resource ${resource.text}`}
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="delete-icon">
                      <title id="delete-icon">Delete resource</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Add New Resource */}
        <div className="space-y-4">
          <h4 className="text-lg font-medium text-gray-800 dark:text-gray-200">Add New Resource</h4>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="resource-text" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Resource Name
              </label>
              <input
                id="resource-text"
                type="text"
                value={newResource.text}
                onChange={(e) => setNewResource({ ...newResource, text: e.target.value })}
                placeholder="e.g., MDN Documentation"
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:text-white"
              />
            </div>
            
            <div>
              <label htmlFor="resource-link" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Resource URL
              </label>
              <input
                id="resource-link"
                type="url"
                value={newResource.link}
                onChange={(e) => setNewResource({ ...newResource, link: e.target.value })}
                placeholder="https://example.com"
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:text-white"
              />
            </div>
          </div>
          
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
          
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleAddResource}
              className="px-4 py-2 bg-teal-600 hover:bg-teal-500 dark:bg-teal-700 dark:hover:bg-teal-600 text-white font-medium rounded-md shadow-sm transition-colors"
            >
              Add Resource
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceEditor;
