export default [
  {
    id: 'fullstack_dec_en',
    name: 'fullstack_dec_en',
    lngNs: 'fullstack_dec_en',
    hasDetails: true,
    registrationAllowed: false,
    date: new Date(2024, 0, 15),
    image: '/images/landing/fullstack.svg',
    price: {
      default: 50,
      uk: 50,
      ru: 50,
      en: 50
    }
  },
  {
    id: 'ai-hustler',
    name: 'ai-hustler',
    lngNs: 'ai-hustler',
    hasDetails: true,
    registrationAllowed: true,
    availableForStudentsBefore: new Date(2024, 11, 1), // Dec 1, 2024
    date: new Date(2025, 2, 22),
    image: '/images/landing/ai-hustler.svg',
    price: {
      default: 0,
      uk: 0,
      ru: 0,
      en: 0
    }
  },
  {
    id: 'fullstack_2025',
    name: 'fullstack_2025',
    lngNs: 'fullstack_2025',
    hasDetails: true,
    registrationAllowed: true,
    date: new Date(2025, 5, 15),
    image: '/images/landing/fullstack.svg',
    price: {
      default: 50,
      uk: 50,
      ru: 50,
      en: 50
    }
  },
  {
    id: 'blockchain',
    name: 'blockchain',
    lngNs: 'blockchain',
    hasDetails: true,
    registrationAllowed: true,
    availableForStudentsBefore: new Date(2024, 11, 1), // Dec 1, 2024
    date: new Date(2025, 2, 10),
    image: '/images/landing/blockchain.svg',
    price: {
      default: 0,
      uk: 0,
      ru: 0,
      en: 0
    }
  }
]
