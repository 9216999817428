import type { PartialTaskResult } from '../../../interfaces/homework'

interface TaskErrorProps {
  result: PartialTaskResult
}

const TaskError: React.FC<TaskErrorProps> = ({ result }) => {
  if (result.status === 'accepted') {
    return (
      <div className="bg-orange-50 dark:bg-orange-900/20 border border-orange-200 dark:border-orange-800 rounded-lg p-4 mb-4">
        <div className="flex items-center">
          <svg className="w-5 h-5 text-orange-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img">
            <title>Processing task</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span className="text-orange-700 dark:text-orange-300 font-medium">
            Task was accepted. Waiting for the result...
          </span>
        </div>
      </div>
    )
  }

  // Handle ESLint validation errors
  if (result.error === 'Validation failed' && Array.isArray(result.details)) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4 mb-4">
        <div className="flex items-center mb-3">
          <svg className="w-5 h-5 text-red-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img">
            <title>Validation error</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="font-semibold text-red-800 dark:text-red-400">{result.error}</div>
        </div>
        <div className="text-red-700 dark:text-red-300 space-y-2 ml-7">
          {result.details.map((detail: string) => (
            <div key={`validation-error-${detail}`} className="flex items-start">
              <span className="block font-mono text-sm bg-red-100 dark:bg-red-900/30 px-3 py-1 rounded whitespace-pre-wrap">
                {detail}
              </span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (result.status !== 'error') {
    return null
  }

  // Handle error messages array
  if (Array.isArray(result.messages) && result.messages.length > 0) {
    return (
      <div className="space-y-3">
        {result.messages.map(({ message }) => {
          const [lines, ...messageBody] = message.split(' ')
          return (
            <div
              key={message}
              className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4"
              role="alert"
            >
              <div className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-3 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img">
                  <title>Error message</title>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <div>
                  <strong className="font-medium text-red-800 dark:text-red-400">{lines}</strong>
                  <span className="block text-red-700 dark:text-red-300 mt-1">{messageBody.join(' ')}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  // Handle test case failures
  if (result.message?.includes('actual')) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4 mb-4">
        <div className="flex items-center mb-3">
          <svg className="w-5 h-5 text-red-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img">
            <title>Test case failure</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="font-semibold text-red-800 dark:text-red-400">Test Failed</div>
        </div>
        <div className="text-red-700 dark:text-red-300 space-y-3 ml-7">
          <div className="bg-red-100 dark:bg-red-900/30 px-3 py-2 rounded">
            <span className="font-medium">Test Result: </span>
            <code>{result.actual !== undefined ? JSON.stringify(result.actual) : 'undefined'}</code>
          </div>
          <div className="bg-red-100 dark:bg-red-900/30 px-3 py-2 rounded">
            <span className="font-medium">Expected: </span>
            <code>{result.expected !== undefined ? JSON.stringify(result.expected) : 'undefined'}</code>
          </div>
          <div className="bg-red-100 dark:bg-red-900/30 px-3 py-2 rounded">
            <span className="font-medium">Message: </span>
            <span className="font-mono text-sm">{result.message?.replace(/ /g, '\u00a0')}</span>
          </div>
        </div>
      </div>
    )
  }

  // Handle general error message
  if (result.message) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4 mb-4">
        <div className="flex items-center">
          <svg className="w-5 h-5 text-red-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" role="img">
            <title>General error</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div>
            <div className="font-semibold text-red-800 dark:text-red-400 mb-1">Test Failed</div>
            <div className="text-red-700 dark:text-red-300 font-mono text-sm">
              {result.message.replace(/ /g, '\u00a0')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default TaskError
