import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import Cookies from 'universal-cookie'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { tryLogin } from '@data/reducers/authentication'
import { useAppDispatch, useAppSelector } from '@data'
import Loader from '../common/loader'

const cookies = new Cookies()

const OAUTH_URLS = {
  linkedIn:
    'https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_emailaddress%20r_liteprofile&client_id=816yv63ae42fk6&redirect_uri=https://skillcrucial.com/api/v1/social/callback/linkedin',
  github:
    'https://github.com/login/oauth/authorize?scope=user:email&client_id=bb227ba728f10e580464',
  stackOverflow:
    'https://stackoverflow.com/oauth?client_id=16013&scope=private_info&redirect_uri=https://skillcrucial.com/api/v1/social/callback/stackoverflow',
  twitter:
    'https://twitter.com/oauth?client_id=16013&scope=private_info&redirect_uri=https://skillcrucial.com/api/v1/social/callback/stackoverflow'
}

interface SocialData {
  [key: string]: unknown
  login?: string
  display_name?: string
  localizedFirstName?: string
  localizedLastName?: string
}

interface User {
  github: SocialData
  stackOverflow: SocialData
  linkedIn: SocialData
  twitter: SocialData
  codewarsApiKey: string
  codewarsUserName: string
  email: string
  sid?: string
}

const SocialConnected = () => {
  const user = useAppSelector((s) => s.authentication.user) as Partial<User>
  const dispatch = useAppDispatch()

  const [githubData, setGithubData] = useState<SocialData>(
    user?.github?.login ? user.github : cookies.get('github_data') || {}
  )
  const [stackOverflowData, setStackoverflowData] = useState<SocialData>(
    user?.stackOverflow?.display_name ? user.stackOverflow : cookies.get('stackoverflow_data') || {}
  )
  const [linkedInData, setLinkedinData] = useState<SocialData>(
    user?.linkedIn?.localizedLastName ? user.linkedIn : cookies.get('linkedin_data') || {}
  )
  const [twitterData] = useState<SocialData>(cookies.get('twitter_data') || {})
  const [codewarsApiKey, setCodewarsApiKey] = useState(user?.codewarsApiKey || '')
  const [codewarsUserName, setCodewarsUserName] = useState(user?.codewarsUserName || '')

  const removeSocialAccount = (platform: string, setterFunction: Dispatch<SetStateAction<SocialData>>) => {
    axios.delete(`/api/v1/user/social/${platform}`).then(() => {
      dispatch(tryLogin())
      toastr.info('!', `${platform} was removed from account`)
      cookies.remove(`${platform.toLowerCase()}_data`)
      setterFunction({})
    })
  }

  const renderSocialButton = (platform: string, data: SocialData, url: string, icon: string) => {
    const isConnected = data && Object.keys(data).length > 0
    const buttonClass = `relative flex items-center w-full py-2 text-sm font-medium transition-all duration-200 ${
      isConnected 
        ? 'text-gray-700 dark:text-gray-200' 
        : 'text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300'
    }`

    const getUserDisplayName = () => {
      if (platform === 'StackOverflow' && data?.display_name) {
        return data.display_name
      }
      if (platform === 'LinkedIn' && data?.localizedFirstName && data?.localizedLastName) {
        return `${data.localizedFirstName} ${data.localizedLastName}`
      }
      if (platform === 'Github' && data?.login) {
        return data.login
      }
      return 'Connected'
    }

    const isNotSaved = () => {
      const lowercasePlatform = platform.toLowerCase() as keyof User
      if (user[lowercasePlatform]) {
        const key = Object.keys(data)[0]
        const socialData = user[lowercasePlatform] as SocialData
        return !socialData[key]
      }
      return false
    }

    return (
      <div className="mt-6 first:mt-0">
        <div className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">{platform}</div>
        {isConnected ? (
          <div className="flex items-center">
            <div className={buttonClass}>
              <i className={`fab fa-${icon} text-lg mr-3 ${
                platform.toLowerCase() === 'github' ? 'text-gray-700 dark:text-gray-200' :
                platform.toLowerCase() === 'stackoverflow' ? 'text-orange-500' :
                platform.toLowerCase() === 'linkedin' ? 'text-blue-600' : ''
              }`} />
              <span className="flex-1">{getUserDisplayName()}</span>
              {isNotSaved() && (
                <span className="ml-2 text-xs font-medium text-red-600 dark:text-red-400">
                  Not Saved
                </span>
              )}
            </div>
            <button
              className="ml-3"
              type="button"
              onClick={() => removeSocialAccount(platform, () => {})}
              title={`Remove ${platform} connection`}
            >
              <XCircleIcon className="h-5 w-5 text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400" />
            </button>
          </div>
        ) : (
          <a className={buttonClass} href={url}>
            <i className={`fab fa-${icon} text-lg mr-3 ${
              platform.toLowerCase() === 'github' ? 'text-gray-700 dark:text-gray-200' :
              platform.toLowerCase() === 'stackoverflow' ? 'text-orange-500' :
              platform.toLowerCase() === 'linkedin' ? 'text-blue-600' : ''
            }`} />
            <span className="flex-1">Connect {platform}</span>
          </a>
        )}
      </div>
    )
  }

  if (!user?.email) {
    return <Loader />
  }

  return (
    <div className="space-y-6">
      {renderSocialButton(
        'StackOverflow',
        stackOverflowData,
        OAUTH_URLS.stackOverflow,
        'stack-overflow'
      )}
      {renderSocialButton('Github', githubData, OAUTH_URLS.github, 'github')}
      {renderSocialButton('LinkedIn', linkedInData, OAUTH_URLS.linkedIn, 'linkedin')}

      <div className="hidden">
        {renderSocialButton('Twitter', twitterData, OAUTH_URLS.twitter, 'twitter')}
      </div>

      <div className="mt-12">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Codewars Integration</h3>
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              Connect your Codewars account to track your progress
            </p>
          </div>
          <div className="flex items-center">
            <span className="text-sm font-semibold text-red-600 dark:text-red-400">Code</span>
            <span className="text-sm font-semibold text-gray-700 dark:text-gray-300">wars</span>
          </div>
        </div>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="cw-api-token" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              API Access Token
            </label>
            <input
              id="cw-api-token"
              className="block w-full py-1.5 text-sm border-0 border-b border-gray-300 dark:border-gray-600 bg-transparent text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-0 focus:border-blue-500 transition-colors duration-200"
              value={codewarsApiKey}
              placeholder="Enter your Codewars API token"
              onChange={(e) => setCodewarsApiKey(e.target.value)}
            />
          </div>
          
          <div>
            <label htmlFor="cw-username" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Username
            </label>
            <input
              id="cw-username"
              className="block w-full py-1.5 text-sm border-0 border-b border-gray-300 dark:border-gray-600 bg-transparent text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-0 focus:border-blue-500 transition-colors duration-200"
              value={codewarsUserName}
              placeholder="Enter your Codewars username"
              onChange={(e) => setCodewarsUserName(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 flex justify-end">
        <button
          className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 transition-colors duration-200"
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.nativeEvent.preventDefault()
            // Uncomment and implement the updateSocialsNetworks action if needed
            // dispatch(
            //   updateSocialsNetworks({
            //     github: githubData,
            //     linkedIn: linkedInData,
            //     stackOverflow: stackOverflowData,
            //     codewarsApiKey,
            //     codewarsUserName
            //   })
            // )
          }}
        >
          Save Changes
        </button>
      </div>
    </div>
  )
}

export default SocialConnected
