import React from 'react'
import ChangePassword from './change-password'

const Security = () => {
  return (
    <div className="space-y-16">
      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Change Password</h3>
        <ChangePassword />
      </div>
    </div>
  )
}

export default Security
