import React, { useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Head from '../head'
import Register from './default/sections/modal'
import Header from './default/sections/header'
import Footer from './default/sections/footer'
import Vimeo from './default/sections/vimeo'
import { LoadingSpinner } from "../ui/loading-spinner"
import { formatDate, getLanguage } from '../../config/i18n'
import courses from '../../config/courses'

interface AboutItem {
  title: string;
  text: string;
}

interface LearningPathItem {
  title: string;
  list: (string | { text: string })[];
}

interface FAQItem {
  question: string;
  answer: string;
}

const CourseSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <section className="border-t border-gray-100 dark:border-gray-800">
    <div className="container mx-auto px-4 py-10">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8">{title}</h2>
      {children}
    </div>
  </section>
)

const AboutItemComponent = ({ title, text }: AboutItem) => (
  <div className="mb-8">
    <h3 className="text-xl mb-2 text-teal-700 dark:text-teal-400 font-medium">{title}</h3>
    <p className="text-gray-600 dark:text-gray-300">{text}</p>
  </div>
)

const LearningPathItemComponent = ({ title, list }: LearningPathItem) => (
  <div className="mb-8">
    <h3 className="text-xl mb-4 text-teal-700 dark:text-teal-400 font-medium">{title}</h3>
    <ul className="space-y-2">
      {list.map((item, index) => (
        <li key={typeof item === 'string' ? item : item.text} className="flex">
          <i className="fas fa-check text-teal-500 mt-1.5 mr-3" />
          <span className="text-gray-600 dark:text-gray-300">{typeof item === 'string' ? item : item.text}</span>
        </li>
      ))}
    </ul>
  </div>
)

const FAQItemComponent = ({ question, answer }: FAQItem) => (
  <div className="mb-8">
    <h3 className="text-xl mb-2 text-teal-700 dark:text-teal-400 font-medium">{question}</h3>
    {answer.split('\n').map((it) => (
      <p className="text-gray-600 dark:text-gray-300 mb-2" key={it}>{it}</p>
    ))}
  </div>
)

const CourseDetails = () => {
  const { key } = useParams()
  const navigate = useNavigate()
  const [modalIsVisible, setModalVisibility] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const course = courses.find(({ id }) => key === id)
  const { t: tb } = useTranslation()
  const { t } = useTranslation(`courses/${course?.lngNs}`)

  React.useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500)
    return () => clearTimeout(timer)
  }, [])

  const toggleModal = () => {
    if (!course) return () => {}

    return () => {
      if (!course.registrationAllowed) {
        setModalVisibility(!modalIsVisible)
      } else {
        navigate(`/enroll/${course.id}`)
        window.scrollTo(0, 0)
      }
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!course) {
    return (
      <div className="container mx-auto px-4 py-10 text-center">
        <h1 className="text-2xl mb-4 text-gray-900 dark:text-white">Course Not Found</h1>
        <p className="mb-6 text-gray-600 dark:text-gray-300">The course you're looking for doesn't exist.</p>
        <Link to="/courses" className="inline-block px-6 py-2 rounded-lg bg-teal-600 text-white hover:bg-teal-700 transition-colors">
          Browse All Courses
        </Link>
      </div>
    )
  }

  const about = t('about', { returnObjects: true }) || {}
  const aboutItems = Object.entries(about)
    .filter(([key1]) => !Number.isNaN(Number(key1)))
    .map(([, value]) => value as AboutItem)
    .filter((item): item is AboutItem => Boolean(item.text && !item.text.includes('about.')))

  const learn = t('learningPath', { returnObjects: true }) || {}
  const learningItems = Object.entries(learn)
    .filter(([key1]) => !Number.isNaN(Number(key1)))
    .map(([, value]) => ({
      ...value,
      list: Array.isArray((value as LearningPathItem).list) 
        ? (value as LearningPathItem).list 
        : Object.values((value as LearningPathItem).list || {})
    }))
    .filter((item): item is LearningPathItem => Boolean(item.title && !item.title.includes('learningPath.')))

  const faq = t('faq', { returnObjects: true }) || {}
  const faqItems = Object.entries(faq)
    .filter(([key1]) => !Number.isNaN(Number(key1)))
    .map(([, value]) => value as FAQItem)
    .filter((item): item is FAQItem => Boolean(item.question && !item.question.includes('faq.')))

  const videoUrl = t('video')
  const currentLanguage = getLanguage() as keyof typeof course.price
  const isFree = course.price[currentLanguage] === 0
  const isPastCourse = new Date(course.date) < new Date()
  const isRegistrationOpen = !isPastCourse && (!isFree || !course.availableForStudentsBefore || new Date() < course.availableForStudentsBefore)

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Head
        title={`Course - ${t('name')}`}
        keywords={t('seo.keywords')}
        description={t('seo.description')}
        subject={t('seo.subject')}
        ogImage={course.image}
      />
      {modalIsVisible && <Register close={toggleModal()} courseId={course.id} />}
      
      <Header />

      <div className="container mx-auto px-4 pt-20 pb-4">
        <div className="flex items-center space-x-2 text-sm">
          <Link to="/" className="text-teal-700 dark:text-teal-400 hover:bg-teal-50 dark:hover:bg-teal-900/20 px-2 py-1 rounded-lg transition-colors">Home</Link>
          <span className="text-gray-400">/</span>
          <Link to="/courses" className="text-teal-700 dark:text-teal-400 hover:bg-teal-50 dark:hover:bg-teal-900/20 px-2 py-1 rounded-lg transition-colors">Courses</Link>
          <span className="text-gray-400">/</span>
          <span className="text-gray-600 dark:text-gray-400">{t('name')}</span>
        </div>
      </div>

      <div className="container mx-auto px-4 py-10">
        <div className="flex flex-col md:flex-row md:space-x-8 items-start">
          <div className="md:w-7/12">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">{t('name')}</h1>
            <div className="text-gray-600 dark:text-gray-300 mb-6">
              {tb('courses.common.launchingOn')}&nbsp;
              <span className="text-teal-700 dark:text-teal-400">{formatDate(course.date)}</span>
            </div>
            <div className="space-y-6">
              <div className="text-gray-600 dark:text-gray-300">{t('description')}</div>
              {aboutItems.slice(0, 2).map((item) => (
                <div key={item.title}>
                  <h3 className="text-xl mb-2 text-teal-700 dark:text-teal-400 font-medium">{item.title}</h3>
                  <p className="text-gray-600 dark:text-gray-300">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-5/12 mt-8 md:mt-0">
            <div className="sticky top-24 space-y-6">
              {videoUrl && videoUrl !== 'video' && (
                <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden">
                  <Vimeo url={videoUrl} />
                </div>
              )}
              <div className="bg-teal-50 dark:bg-teal-900/20 rounded-xl p-6">
                {isFree ? (
                  <>
                    <div className="text-2xl mb-2 text-teal-700 dark:text-teal-400 font-medium">Free Course</div>
                    {course.availableForStudentsBefore && (
                      <div className="text-gray-600 dark:text-gray-300 mb-4">
                        Available for students who enrolled before {formatDate(course.availableForStudentsBefore)}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="text-3xl mb-2 text-teal-700 dark:text-teal-400">
                      ${course.price[currentLanguage]}
                      <span className="text-lg text-gray-500 dark:text-gray-400">/{tb('courses.common.month')}</span>
                    </div>
                    <div className="text-teal-700 dark:text-teal-400 text-sm mb-4">
                      {tb('courses.common.californiaEducationPrice')}
                    </div>
                  </>
                )}
                {isPastCourse ? (
                  <div className="text-gray-500 dark:text-gray-400 text-center py-2">
                    {tb('courses.status.passed')}
                  </div>
                ) : isRegistrationOpen ? (
                  <button
                    type="button"
                    className="w-full px-6 py-3 rounded-lg bg-teal-600 text-white hover:bg-teal-700 transition-colors"
                    onClick={toggleModal()}
                  >
                    {tb('courses.common.registration')}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CourseSection title={tb('courses.common.abountTheCourse')}>
        <div className="max-w-3xl">
          <div className="mb-8">
            <p className="text-gray-600 dark:text-gray-300">{t('description')}</p>
          </div>
          {aboutItems.slice(2).map((item) => (
            <AboutItemComponent key={item.title} {...item} />
          ))}
        </div>
      </CourseSection>

      <CourseSection title={tb('courses.common.whatToExpect')}>
        <div className="max-w-3xl">
          {learningItems.map((item) => (
            <LearningPathItemComponent key={item.title} {...item} />
          ))}
        </div>
      </CourseSection>

      <CourseSection title={tb('courses.common.faq')}>
        <div className="max-w-3xl">
          {faqItems.map((item) => (
            <FAQItemComponent key={item.question} {...item} />
          ))}
        </div>
      </CourseSection>

      <Footer />
    </div>
  )
}

export default CourseDetails
