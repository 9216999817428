import React, { useEffect, useState, useRef } from 'react'
import { Volume2, VolumeX, Pause, Settings, ChevronUp, ChevronDown } from 'lucide-react'
import type { TextToSpeechProps, VoiceOption } from './types'

// Voice gender types
type VoiceGender = 'all' | 'male' | 'female';

// Common male and female name patterns to guess voice gender
const malePatterns = ['male', 'man', 'guy', 'boy', 'david', 'john', 'james', 'robert', 'michael', 'william', 'alex', 'daniel', 'matthew', 'thomas', 'ivan', 'dmitry', 'sergey', 'viktor'];
const femalePatterns = ['female', 'woman', 'girl', 'lady', 'mary', 'jennifer', 'linda', 'patricia', 'elizabeth', 'susan', 'jessica', 'sarah', 'karen', 'nancy', 'lisa', 'anna', 'olga', 'elena', 'natalia'];

// Guess gender based on voice name
const guessVoiceGender = (voiceName: string): VoiceGender => {
  const lowerName = voiceName.toLowerCase();
  
  // Check for explicit gender indicators
  for (const pattern of malePatterns) {
    if (lowerName.includes(pattern)) return 'male';
  }
  
  for (const pattern of femalePatterns) {
    if (lowerName.includes(pattern)) return 'female';
  }
  
  // Default to female for Russian voices (most common)
  if (lowerName.includes('ru-ru')) return 'female';
  
  // Otherwise, we can't determine
  return 'all';
};

export const TextToSpeech: React.FC<TextToSpeechProps> = ({ text }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isSupported, setIsSupported] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [rate, setRate] = useState(0.9); // Default rate
  const [pitch, setPitch] = useState(1.0);
  const [selectedVoice, setSelectedVoice] = useState<SpeechSynthesisVoice | null>(null);
  const [availableVoices, setAvailableVoices] = useState<VoiceOption[]>([]);
  const [filteredVoices, setFilteredVoices] = useState<VoiceOption[]>([]);
  const [voiceGender, setVoiceGender] = useState<VoiceGender>('all');
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [skipTableOfContents, setSkipTableOfContents] = useState(true);
  
  const speechSynthRef = useRef<SpeechSynthesis | null>(null);
  const sentencesRef = useRef<string[]>([]);
  const isProcessingRef = useRef(false);

  // Filter voices by gender
  useEffect(() => {
    if (voiceGender === 'all') {
      setFilteredVoices(availableVoices);
    } else {
      setFilteredVoices(
        availableVoices.filter(option => 
          guessVoiceGender(option.voice.name) === voiceGender
        )
      );
    }
  }, [availableVoices, voiceGender]);

  useEffect(() => {
    // Check if browser supports speech synthesis
    if (typeof window !== 'undefined' && window.speechSynthesis) {
      speechSynthRef.current = window.speechSynthesis;
      setIsSupported(true);
      
      // Load available voices
      const loadVoices = () => {
        const voices = speechSynthRef.current?.getVoices() || [];
        
        // Filter for Russian voices first, then any available voices
        const russianVoices = voices.filter(voice => voice.lang.includes('ru'));
        const voiceOptions = russianVoices.length > 0 
          ? russianVoices.map(voice => ({ 
              voice, 
              label: `${voice.name} (${voice.lang})` 
            }))
          : voices.map(voice => ({ 
              voice, 
              label: `${voice.name} (${voice.lang})` 
            }));
            
        setAvailableVoices(voiceOptions);
        
        // Set default voice (prefer Russian)
        if (russianVoices.length > 0) {
          // Try to find a more natural-sounding voice (often contains "natural" in the name)
          const naturalVoice = russianVoices.find(voice => 
            voice.name.toLowerCase().includes('natural') || 
            voice.name.toLowerCase().includes('neural')
          );
          
          // Try to find a male voice if available
          const maleVoice = russianVoices.find(voice => 
            guessVoiceGender(voice.name) === 'male'
          );
          
          setSelectedVoice(maleVoice || naturalVoice || russianVoices[0]);
        } else if (voices.length > 0) {
          // Try to find a more natural-sounding voice
          const naturalVoice = voices.find(voice => 
            voice.name.toLowerCase().includes('natural') || 
            voice.name.toLowerCase().includes('neural')
          );
          
          // Try to find a male voice if available
          const maleVoice = voices.find(voice => 
            guessVoiceGender(voice.name) === 'male'
          );
          
          setSelectedVoice(maleVoice || naturalVoice || voices[0]);
        }
      };
      
      // Chrome loads voices asynchronously
      if (speechSynthRef.current.onvoiceschanged !== undefined) {
        speechSynthRef.current.onvoiceschanged = loadVoices;
      }
      
      loadVoices();
    } else {
      setIsSupported(false);
    }

    // Cleanup function to stop speech when component unmounts
    return () => {
      if (speechSynthRef.current) {
        speechSynthRef.current.cancel();
      }
    };
  }, []);

  // Check if a section looks like a table of contents
  const isTableOfContents = (text2: string): boolean => {
    // Common patterns for table of contents
    const tocPatterns = [
      /^(содержание|оглавление|table of contents|contents)/i,
      /^\s*\d+\.\s+.+\s*\d+\s*$/m, // Numbered items with page numbers
      /^(\d+\.){2,}\s+/m, // Multi-level numbering (e.g., 1.2.3)
      /^\s*•\s+.+\s*\d+\s*$/m, // Bullet points with page numbers
    ];
    
    // Check for common TOC patterns
    for (const pattern of tocPatterns) {
      if (pattern.test(text2)) return true;
    }
    
    // Check for consecutive numbered lines (common in TOCs)
    const lines = text2.split('\n');
    let numberedLines = 0;
    
    for (const line of lines) {
      if (/^\s*\d+[\.\)]\s+/.test(line)) {
        numberedLines++;
        if (numberedLines >= 3) return true; // Three consecutive numbered lines
      } else {
        numberedLines = 0;
      }
    }
    
    // Check for Russian-style TOC with numbered list items and links
    if (/Содержание/i.test(text2) && /\d+\.\s+[А-Яа-я]/.test(text2)) {
      return true;
    }
    
    // Check for list-like structure with bullet points or numbers
    let listItemCount = 0;
    for (const line of lines) {
      if (/^\s*[\d•\-*]\.\s+/.test(line) || /^\s*•\s+/.test(line)) {
        listItemCount++;
        if (listItemCount >= 3) return true;
      }
    }
    
    return false;
  };

  // Extract plain text from markdown content and split into sentences
  const preprocessText = (markdown: string): string[] => {
    try {
      // Remove HTML tags
      let plainText = markdown.replace(/<[^>]*>/g, ' ');
      
      // Remove markdown formatting
      plainText = plainText.replace(/\*\*(.*?)\*\*/g, '$1'); // Bold
      plainText = plainText.replace(/\*(.*?)\*/g, '$1');     // Italic
      plainText = plainText.replace(/\[(.*?)\]\(.*?\)/g, '$1'); // Links
      plainText = plainText.replace(/#{1,6}\s(.*?)(\n|$)/g, '$1. '); // Headers
      plainText = plainText.replace(/`(.*?)`/g, '$1');      // Inline code
      plainText = plainText.replace(/```[\s\S]*?```/g, ''); // Code blocks
      plainText = plainText.replace(/\$\$(.*?)\$\$/g, '');  // Math blocks
      plainText = plainText.replace(/\$(.*?)\$/g, '');      // Inline math
      
      // Split the text into paragraphs
      const paragraphs = plainText.split(/\n\s*\n/);
      
      // Filter out table of contents sections if enabled
      const filteredParagraphs = skipTableOfContents 
        ? paragraphs.filter(para => !isTableOfContents(para))
        : paragraphs;
      
      // Rejoin paragraphs with proper spacing
      plainText = filteredParagraphs.join('. ');
      
      // Replace multiple spaces and newlines with a single space
      plainText = plainText.replace(/\s+/g, ' ');
      
      // Split text into sentences
      // This regex splits on periods, question marks, and exclamation marks
      // followed by a space or end of string
      const sentences = plainText.split(/(?<=[.!?])\s+|(?<=[.!?])$/);
      
      // Filter out empty sentences and trim whitespace
      return sentences
        .filter(sentence => sentence.trim().length > 0)
        .map(sentence => sentence.trim());
    } catch (error) {
      console.error('Error preprocessing text:', error);
      return [markdown]; // Return original text as a fallback
    }
  };

  const speakNextSentence = () => {
    if (!speechSynthRef.current || !isSupported || !sentencesRef.current.length || isProcessingRef.current) return;
    
    isProcessingRef.current = true;
    
    try {
      if (currentSentenceIndex >= sentencesRef.current.length) {
        // End of content reached
        setIsSpeaking(false);
        setCurrentSentenceIndex(0);
        isProcessingRef.current = false;
        return;
      }
      
      const sentence = sentencesRef.current[currentSentenceIndex];
      const utterance = new SpeechSynthesisUtterance(sentence);
      
      // Set voice if available
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }
      
      // Set language to match the content
      utterance.lang = selectedVoice?.lang || 'ru-RU';
      
      // Set speech rate and pitch
      utterance.rate = rate;
      utterance.pitch = pitch;
      
      // Event handlers
      utterance.onend = () => {
        // Move to next sentence
        setCurrentSentenceIndex(prevIndex => prevIndex + 1);
        isProcessingRef.current = false;
      };
      
      utterance.onerror = (event) => {
        console.error('Speech synthesis error:', event);
        // Try to continue with the next sentence despite the error
        setCurrentSentenceIndex(prevIndex => prevIndex + 1);
        isProcessingRef.current = false;
      };
      
      // Start speaking
      speechSynthRef.current.speak(utterance);
    } catch (error) {
      console.error('Error in speakNextSentence:', error);
      isProcessingRef.current = false;
    }
  };

  // Effect to handle speaking sentences in sequence
  useEffect(() => {
    if (isSpeaking && !isPaused) {
      speakNextSentence();
    }
  }, [isSpeaking, isPaused, currentSentenceIndex]);

  const startSpeech = () => {
    if (!speechSynthRef.current || !isSupported) return;

    try {
      // Cancel any ongoing speech
      speechSynthRef.current.cancel();
      
      // Preprocess text into sentences
      sentencesRef.current = preprocessText(text);
      
      // Start speaking from the first sentence
      setIsSpeaking(true);
      setIsPaused(false);
      setCurrentSentenceIndex(0);
    } catch (error) {
      console.error('Error starting speech:', error);
    }
  };

  const pauseSpeech = () => {
    if (!speechSynthRef.current || !isSupported) return;

    try {
      if (isSpeaking && !isPaused) {
        speechSynthRef.current.pause();
        setIsPaused(true);
      } else if (isPaused) {
        speechSynthRef.current.resume();
        setIsPaused(false);
      }
    } catch (error) {
      console.error('Error pausing/resuming speech:', error);
    }
  };

  const stopSpeech = () => {
    if (!speechSynthRef.current || !isSupported) return;

    try {
      speechSynthRef.current.cancel();
      setIsSpeaking(false);
      setIsPaused(false);
      setCurrentSentenceIndex(0);
      isProcessingRef.current = false;
    } catch (error) {
      console.error('Error stopping speech:', error);
    }
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  if (!isSupported) {
    return null; // Don't render anything if speech synthesis is not supported
  }

  return (
    <div className="fixed bottom-6 right-6 flex flex-col items-end space-y-2 z-50">
      {showSettings && (
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg mb-2 w-64">
          <h3 className="text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Speech Settings</h3>
          
          <div className="mb-3">
            <label className="block text-xs text-gray-600 dark:text-gray-400 mb-1">Voice Gender</label>
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={() => setVoiceGender('all')}
                className={`px-2 py-1 text-xs rounded ${
                  voiceGender === 'all' 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                }`}
                disabled={isSpeaking}
              >
                All
              </button>
              <button
                type="button"
                onClick={() => setVoiceGender('male')}
                className={`px-2 py-1 text-xs rounded ${
                  voiceGender === 'male' 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                }`}
                disabled={isSpeaking}
              >
                Male
              </button>
              <button
                type="button"
                onClick={() => setVoiceGender('female')}
                className={`px-2 py-1 text-xs rounded ${
                  voiceGender === 'female' 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                }`}
                disabled={isSpeaking}
              >
                Female
              </button>
            </div>
          </div>
          
          <div className="mb-3">
            <label className="block text-xs text-gray-600 dark:text-gray-400 mb-1">Voice</label>
            <select 
              className="w-full px-2 py-1 text-sm rounded border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              value={selectedVoice?.name || ""}
              onChange={(e) => {
                const selected = availableVoices.find(v => v.voice.name === e.target.value);
                if (selected) setSelectedVoice(selected.voice);
              }}
              disabled={isSpeaking}
            >
              {filteredVoices.map((option, i) => (
                <option key={`voice-${i}-${option.voice.name}`} value={option.voice.name}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          
          <div className="mb-3">
            <label className="block text-xs text-gray-600 dark:text-gray-400 mb-1">
              Speed: {rate.toFixed(1)}
            </label>
            <div className="flex items-center">
              <button 
                type="button"
                className="p-1 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                onClick={() => setRate(Math.max(0.1, rate - 0.1))}
                disabled={isSpeaking}
                aria-label="Decrease speed"
              >
                <ChevronDown size={16} />
              </button>
              <input
                type="range"
                min="0.1"
                max="2"
                step="0.1"
                value={rate}
                onChange={(e) => setRate(Number.parseFloat(e.target.value))}
                className="flex-1 mx-2"
                disabled={isSpeaking}
              />
              <button 
                type="button"
                className="p-1 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                onClick={() => setRate(Math.min(2, rate + 0.1))}
                disabled={isSpeaking}
                aria-label="Increase speed"
              >
                <ChevronUp size={16} />
              </button>
            </div>
          </div>
          
          <div className="mb-3">
            <label className="block text-xs text-gray-600 dark:text-gray-400 mb-1">
              Pitch: {pitch.toFixed(1)}
            </label>
            <div className="flex items-center">
              <button 
                type="button"
                className="p-1 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                onClick={() => setPitch(Math.max(0.1, pitch - 0.1))}
                disabled={isSpeaking}
                aria-label="Decrease pitch"
              >
                <ChevronDown size={16} />
              </button>
              <input
                type="range"
                min="0.1"
                max="2"
                step="0.1"
                value={pitch}
                onChange={(e) => setPitch(Number.parseFloat(e.target.value))}
                className="flex-1 mx-2"
                disabled={isSpeaking}
              />
              <button 
                type="button"
                className="p-1 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                onClick={() => setPitch(Math.min(2, pitch + 0.1))}
                disabled={isSpeaking}
                aria-label="Increase pitch"
              >
                <ChevronUp size={16} />
              </button>
            </div>
          </div>
          
          <div className="mb-2">
            <label className="flex items-center text-xs text-gray-600 dark:text-gray-400">
              <input
                type="checkbox"
                checked={skipTableOfContents}
                onChange={() => setSkipTableOfContents(!skipTableOfContents)}
                className="mr-2"
                disabled={isSpeaking}
              />
              Skip table of contents
            </label>
          </div>
        </div>
      )}
      
      <div className="flex space-x-2">
        {!isSpeaking ? (
          <>
            <button
              type="button"
              onClick={startSpeech}
              className="p-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full shadow-lg transition-colors duration-200"
              aria-label="Listen to lecture"
              title="Listen to lecture"
            >
              <Volume2 size={24} />
            </button>
            <button
              type="button"
              onClick={toggleSettings}
              className="p-3 bg-gray-500 hover:bg-gray-600 text-white rounded-full shadow-lg transition-colors duration-200"
              aria-label="Speech settings"
              title="Speech settings"
            >
              <Settings size={24} />
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={pauseSpeech}
              className="p-3 bg-yellow-500 hover:bg-yellow-600 text-white rounded-full shadow-lg transition-colors duration-200"
              aria-label={isPaused ? "Resume speech" : "Pause speech"}
              title={isPaused ? "Resume speech" : "Pause speech"}
            >
              <Pause size={24} />
            </button>
            <button
              type="button"
              onClick={stopSpeech}
              className="p-3 bg-red-500 hover:bg-red-600 text-white rounded-full shadow-lg transition-colors duration-200"
              aria-label="Stop speech"
              title="Stop speech"
            >
              <VolumeX size={24} />
            </button>
          </>
        )}
      </div>
      
      {isSpeaking && sentencesRef.current.length > 0 && currentSentenceIndex < sentencesRef.current.length && (
        <div className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow-lg mt-2 max-w-md">
          <p className="text-sm text-gray-700 dark:text-gray-300">
            {sentencesRef.current[currentSentenceIndex]}
          </p>
          <div className="mt-2 bg-gray-200 dark:bg-gray-700 h-1 rounded-full overflow-hidden">
            <div 
              className="bg-blue-500 h-full rounded-full"
              style={{ width: `${(currentSentenceIndex / sentencesRef.current.length) * 100}%` }}
            />
          </div>
          <div className="mt-1 text-xs text-gray-500 dark:text-gray-400 text-right">
            {currentSentenceIndex + 1} / {sentencesRef.current.length}
          </div>
        </div>
      )}
    </div>
  );
};
