import React, { useState, useMemo } from 'react';
import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import Playground from 'javascript-playgrounds';
import { ArrowUpCircle } from 'lucide-react';

import Head from './head';
import Header from './landings/default/sections/header';
import Footer from './landings/default/sections/footer';

const PLAYGROUND_OPTIONS = [
  { value: 'javascript', text: 'Javascript' },
  { value: 'react', text: 'React' },
  { value: 'react-native', text: 'React Native' },
  { value: 'html', text: 'HTML' },
  { value: 'python', text: 'Python' }
];

interface CustomCSSProperties extends CSSProperties {
  '--bg-color'?: string;
  '--fg-color'?: string;
  '--editor-bg-color'?: string;
  '--editor-fg-color'?: string;
  '--syntax-keyword-color'?: string;
  '--syntax-string-color'?: string;
  '--syntax-comment-color'?: string;
}

const Landing = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState('javascript');
  const currentOption = useMemo(() => PLAYGROUND_OPTIONS.find(opt => opt.value === mode), [mode]);

  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const editorStyles = {
    '--bg-color': isDarkMode ? '#1a1b1e' : '#f8f9fa',
    '--fg-color': isDarkMode ? '#e4e5e7' : '#212529',
    '--editor-bg-color': isDarkMode ? '#2a2b2e' : '#ffffff',
    '--editor-fg-color': isDarkMode ? '#e4e5e7' : '#333333',
    '--syntax-keyword-color': '#0d9488', // teal-600
    '--syntax-string-color': '#059669', // emerald-600
    '--syntax-comment-color': isDarkMode ? '#9ca3af' : '#6b7280', // gray-400/500
  } as CustomCSSProperties;

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 dark:bg-gray-900">
      <Head
        title={t('courses.playground.seo.title')}
        keywords={t('courses.playground.seo.keywords')}
        description={t('courses.playground.seo.description')}
        subject={t('courses.playground.seo.subject')}
      />

      <Header />

      <main className="flex-grow container mx-auto px-4 pt-20">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            {t('header.playground')}
          </h1>
          <select
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            className="px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            {PLAYGROUND_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
          <div className="border-b border-gray-200 dark:border-gray-700 px-4 py-3 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className="w-3 h-3 rounded-full bg-red-500" />
              <span className="w-3 h-3 rounded-full bg-yellow-500" />
              <span className="w-3 h-3 rounded-full bg-green-500" />
            </div>
            <div className="text-sm font-medium text-gray-600 dark:text-gray-400">
              {currentOption?.text} Playground
            </div>
            <div className="w-16" /> {/* Spacer for symmetry */}
          </div>
          
          <Playground
            fullscreen
            key={mode}
            preset={mode}
            className="w-full h-[calc(100vh-250px)] min-h-[500px] max-h-[800px]"
            style={editorStyles}
          />
        </div>
      </main>

      <Footer />

      <button 
        type="button"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-6 right-6 bg-teal-600 hover:bg-teal-700 text-white p-2.5 rounded-full shadow-lg transition-colors duration-300 opacity-80 hover:opacity-100"
        aria-label="Go to top"
      >
        <ArrowUpCircle size={20} />
      </button>
    </div>
  );
};

export default Landing;
