import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@data'
import { getLanguage } from '../../../../config/i18n'

const Header = () => {
  const name = useAppSelector((s) => s.authentication.user.name)
  const { t } = useTranslation()

  const links = [
    { to: `/${getLanguage()}/`, name: t('header.home'), end: true },
    { to: `/${getLanguage()}/courses`, name: t('header.courses') },
    { to: `/${getLanguage()}/login`, name: name || t('header.login') }
  ]

  return (
    <nav className="fixed z-30 top-0 w-full bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          <Link
            to="/"
            className="flex items-center space-x-1 text-lg font-mono text-teal-700 dark:text-teal-500"
          >
            <span>SkillCrucial</span>
            <span className="animate-pulse">_</span>
          </Link>

          <ul className="flex items-center space-x-1">
            {links.map((link) => (
              <li key={link.name}>
                <NavLink
                  to={link.to}
                  end={link.end}
                  className={({ isActive }) => `
                    px-4 py-2 rounded-lg text-sm font-medium transition-colors
                    ${
                      isActive
                        ? 'bg-teal-50 text-teal-700 dark:bg-teal-900/20 dark:text-teal-400'
                        : 'text-gray-600 hover:text-teal-700 dark:text-gray-300 dark:hover:text-teal-400 hover:bg-gray-50 dark:hover:bg-gray-700'
                    }
                  `}
                >
                  {link.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
