import classNames from 'classnames'
import type { HomeWorkQuestions } from '../../../../interfaces/mongoose.gen'

interface MultipleQuestionProps {
  questionId: string
  options: HomeWorkQuestions['options']
  selectedValues: string[]
  onChange: (questionId: string, value: string) => void
}

const MultipleQuestion = ({ questionId, options, selectedValues, onChange }: MultipleQuestionProps) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="text-sm font-medium text-teal-400 mb-3 uppercase tracking-wide">
        Select all that apply:
      </div>
      {options.map((option) => {
        const optionId = option._id
        const optionText = option.text

        if (!optionId || typeof optionText !== 'string') return null

        const isSelected = selectedValues.includes(optionText)

        return (
          <div key={optionId} className="flex items-center">
            <button
              type="button"
              onClick={() => onChange(questionId, optionText)}
              className={classNames(
                'flex items-center w-full px-5 py-3 rounded-lg font-medium text-left transition-all duration-300 text-base',
                {
                  'bg-teal-600/30 text-white border border-teal-500 hover:bg-teal-600/40 shadow-lg shadow-teal-500/10 transform hover:-translate-y-1':
                    isSelected,
                  'bg-gray-800 text-gray-300 hover:bg-gray-700 border border-gray-700 hover:border-teal-500/30 transform hover:-translate-y-1':
                    !isSelected
                }
              )}
            >
              <div className={classNames(
                'w-6 h-6 mr-4 flex-shrink-0 border-2 rounded transition-all duration-300 flex items-center justify-center',
                {
                  'bg-teal-500 border-teal-400 shadow-md shadow-teal-500/20': isSelected,
                  'border-gray-500 hover:border-teal-500/50': !isSelected
                }
              )}>
                {isSelected && (
                  <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <title>Checkmark</title>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
              {optionText}
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default MultipleQuestion
