import React from 'react'

// Custom components for tables
export const Table: React.FC<React.HTMLAttributes<HTMLTableElement>> = (props) => (
  <div className="overflow-x-auto my-6">
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 border border-gray-200 dark:border-gray-700 rounded-lg" {...props} />
  </div>
);

export const TableHead: React.FC<React.HTMLAttributes<HTMLTableSectionElement>> = (props) => (
  <thead className="bg-gray-50 dark:bg-gray-800" {...props} />
);

export const TableBody: React.FC<React.HTMLAttributes<HTMLTableSectionElement>> = (props) => (
  <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700" {...props} />
);

export const TableRow: React.FC<React.HTMLAttributes<HTMLTableRowElement>> = (props) => (
  <tr className="hover:bg-gray-50 dark:hover:bg-gray-800" {...props} />
);

export const TableCell: React.FC<React.TdHTMLAttributes<HTMLTableCellElement>> = (props) => (
  <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400 border-r last:border-r-0 border-gray-200 dark:border-gray-700" {...props} />
);

export const TableHeader: React.FC<React.ThHTMLAttributes<HTMLTableHeaderCellElement>> = (props) => (
  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider border-r last:border-r-0 border-gray-200 dark:border-gray-700" {...props} />
);
