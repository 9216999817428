import React from 'react'
import PaymentMethods from '../billing/payment-methods'
import PaymentHistory from '../billing/payment-history'
import BillingHistory from '../billing/billing-history'

const Billing = () => {
  return (
    <div className="space-y-16">
      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Payment Methods</h3>
        <PaymentMethods />
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Payment History</h3>
        <PaymentHistory />
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">
          Billing Information (Old)
        </h3>
        <BillingHistory />
      </div>
    </div>
  )
}

export default Billing
