import type React from 'react'

interface ProgressBarProps {
  answeredQuestions: number
  totalQuestions: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ answeredQuestions, totalQuestions }) => {
  const progressPercentage = (answeredQuestions / totalQuestions) * 100

  return (
    <div className="mb-12 bg-gray-800/80 p-6 rounded-xl shadow-lg border border-gray-700/50">
      <div className="flex justify-between items-center mb-3">
        <span className="text-sm font-medium text-teal-400 uppercase tracking-wider">Your Progress</span>
        <span className="text-sm font-medium text-white bg-teal-600/30 px-3 py-1 rounded-full border border-teal-500/30">
          {answeredQuestions} of {totalQuestions} questions answered
        </span>
      </div>
      <div className="w-full bg-gray-700/50 rounded-full h-3 shadow-inner overflow-hidden">
        <div
          className="bg-gradient-to-r from-teal-500 to-teal-400 h-3 rounded-full transition-all duration-500 shadow-lg"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
      <div className="mt-2 text-xs text-gray-400 text-right">
        {progressPercentage.toFixed(0)}% complete
      </div>
    </div>
  )
}

export default ProgressBar
