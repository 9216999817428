import type { HomeworkJsTasks } from '../../../interfaces/mongoose.gen'
import type { TaskResultMap } from '../../../interfaces/homework'
import SingleJsTask from './single-js-task'

interface Props {
  tasks: HomeworkJsTasks[]
  results: TaskResultMap
  testDataId: string
  homeworkId: string
  onFullscreenToggle: (taskId: string) => void
}

const JSTasks: React.FC<Props> = ({ 
  tasks, 
  results = {}, 
  testDataId, 
  homeworkId,
  onFullscreenToggle
}) => {
  let lastIndex = 0
  let maxOpened = 0

  // First filter available tasks
  const availableTasks = tasks.filter((task, index) => {
    if (!task.id) return false

    const status = results[task.id] || {}
    if (status.result?.status === 'ok') {
      lastIndex = index + 2
    }
    if (!status.result) {
      maxOpened += 1
    }
    return (
      (status.result?.status === 'ok') ||
      ((index <= lastIndex || index <= 1) && maxOpened <= 2)
    )
  })

  return (
    <div className="space-y-8 divide-y divide-gray-100 dark:divide-gray-700">
      {availableTasks.map((task, index) => {
        if (!task.id) return null
        const status = results[task.id] || {}

        return (
          <div key={task.id} className="pt-8 first:pt-0" data-task-id={task.id}>
            <SingleJsTask
              task={task}
              testDataId={testDataId}
              index={index}
              homeworkId={homeworkId}
              result={results[task.id]?.result}
              messages={status.messages}
              code={status.code}
              taskIndex={index}
              onFullscreenToggle={() => onFullscreenToggle(task.id || '')}
            />
          </div>
        )
      })}
    </div>
  )
}

export default JSTasks
