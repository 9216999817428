import type React from 'react'
import type { User } from '../../../../interfaces/mongoose.gen'

type Props = {
  user: Partial<User>
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const UserAvatar: React.FC<Props> = ({ user, size = 'md', className = '' }) => {
  const fallbackImage = '/images/anonymous.png'
  
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = fallbackImage
  }

  const sizeClasses = {
    sm: 'h-8 w-8',
    md: 'h-12 w-12',
    lg: 'h-20 w-20'
  }

  return (
    <img
      className={`${sizeClasses[size]} rounded-full object-cover border-2 border-gray-100 dark:border-gray-700 ${className}`}
      src={user?.stackOverflow?.profile_image || fallbackImage}
      alt={user.name || 'User profile'}
      onError={handleImageError}
    />
  )
}

export default UserAvatar
