import type React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import { getLanguage } from '../../../config/i18n'
import type { CourseData, WeekData } from './types'

interface InterviewBreadcrumbProps {
  course?: CourseData
  week?: WeekData
  courseId?: string
  weekId?: string
}

const InterviewBreadcrumb: React.FC<InterviewBreadcrumbProps> = ({ course, week, courseId, weekId }) => {
  return (
    <nav className="mb-8" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2 text-sm">
        <li>
          <Link to="/" className="text-teal-400 hover:text-teal-300 transition-colors">
            <HomeIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </li>
        <ChevronRightIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
        <li>
          <Link
            to={`/${getLanguage()}/course/${courseId}`}
            className="text-teal-400 hover:text-teal-500 transition-colors"
          >
            {course?.name}
          </Link>
        </li>
        <ChevronRightIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
        <li>
          <Link
            to={`/${getLanguage()}/course/${courseId}/${weekId}`}
            className="text-teal-400 hover:text-teal-300 transition-colors"
          >
            {week?.name}
          </Link>
        </li>
        <ChevronRightIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
        <li>
          <span className="text-gray-400">Interview Questions</span>
        </li>
      </ol>
    </nav>
  )
}

export default InterviewBreadcrumb
