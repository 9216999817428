import type { PartialTaskResult } from '../../../interfaces/homework'

interface TaskHeaderProps {
  index: number
  result?: PartialTaskResult
}

const TaskHeader: React.FC<TaskHeaderProps> = ({ index, result }) => {
  return (
    <h5 className="flex items-center justify-between text-lg font-medium text-gray-900 dark:text-gray-100">
      <div className="flex items-center gap-3">
        <span className="text-sm font-semibold px-2.5 py-1 bg-teal-500/10 dark:bg-teal-500/20 text-teal-700 dark:text-teal-400 rounded-md transition-colors">
          Task {index + 1}
        </span>
      </div>
      
      {result?.status === 'ok' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-6 h-6 text-teal-600 dark:text-teal-400"
          aria-label="Task completed successfully"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <title>Task completed successfully</title>
          <path d="M20 6L9 17l-5-5" />
        </svg>
      )}
    </h5>
  )
}

export default TaskHeader
