import React from 'react'
import type { ReactNode } from 'react'
import { Prism } from 'react-syntax-highlighter'
import type { SyntaxHighlighterProps } from 'react-syntax-highlighter'
import { Clipboard } from 'lucide-react'
import type { PreProps, CodeProps, CodeElementProps } from './types'

const PrismHighlighter = Prism as React.ComponentType<SyntaxHighlighterProps>

// Using dark style for both light and dark modes for better code readability
const inverseStyle = {
  'pre[class*="language-"]': {
    background: '#1e1e1e',  // Dark background
    margin: 0,
    padding: '1rem',
    fontSize: '0.95rem',
    color: '#e1e1e1',  // Light text
    borderRadius: '0.5rem',
    lineHeight: '1.6',
    border: '1px solid #333',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
  },
  'code[class*="language-"]': {
    color: '#e1e1e1',
    fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
    fontWeight: '400'
  },
  'token.comment': {
    color: '#608b4e',  // Green comments
    fontStyle: 'italic'
  },
  'token.punctuation': {
    color: '#808080'  // Gray punctuation
  },
  'token.keyword': {
    color: '#569cd6',  // Blue keywords
    fontWeight: '600'
  },
  'token.string': {
    color: '#ce9178'  // Orange-ish strings
  },
  'token.number': {
    color: '#b5cea8'  // Light green numbers
  },
  'token.boolean': {
    color: '#569cd6'  // Blue booleans
  },
  'token.function': {
    color: '#dcdcaa'  // Yellow functions
  },
  'token.tag': {
    color: '#f92672',  // Pink tags
    fontWeight: '600'
  },
  'token.attr-name': {
    color: '#66d9ef'  // Light blue attributes
  },
  'token.attr-value': {
    color: '#a6e22e'  // Green values
  },
  'token.entity': {
    color: '#f92672'
  },
  'token.operator': {
    color: '#d4d4d4'  // Light gray operators
  },
  'token.property': {
    color: '#9cdcfe'  // Light blue properties
  },
  'token.variable': {
    color: '#9cdcfe'  // Light blue variables
  }
}

export const Pre: React.FC<PreProps> = ({ children, ...props }) => {
  const [copied, setCopied] = React.useState(false)
  const textContent = React.Children.toArray(children)
    .map(child => {
      if (typeof child === 'string') return child
      if (React.isValidElement(child)) {
        // Type assertion to tell TypeScript that child.props exists and has a children property
        return (child.props as { children?: ReactNode }).children || ''
      }
      return ''
    })
    .join('')

  const handleCopy = () => {
    navigator.clipboard.writeText(textContent)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className="group/pre relative">
      <pre {...props} className="!pl-4">
        {children}
      </pre>
      <div className="absolute inset-0 bg-black opacity-0 group-hover/pre:opacity-30 transition-opacity duration-200 z-10 pointer-events-none" />
      <button
        type="button"
        onClick={handleCopy}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 rounded-lg bg-gray-100 dark:bg-gray-700 opacity-0 group-hover/pre:opacity-90 transition-opacity duration-200 hover:bg-gray-200 dark:hover:bg-gray-600 z-20"
        aria-label="Copy code"
      >
        <Clipboard size={24} className={copied ? "text-green-500" : "dark:text-gray-300 text-gray-600"} />
      </button>
    </div>
  )
}

export const Code: React.FC<CodeProps> = ({ className, children, inline }) => {
  const match = /language-(\w+)/.exec(className || '')
  const content = String(children || '');
  
  // Check if this is a math expression (starts with $ and ends with $)
  const isMathExpression = content.startsWith('$') && content.endsWith('$');
  
  // If it's a math expression, don't render it as code (it will be handled by KaTeX)
  if (isMathExpression) {
    return <>{children}</>;
  }

  if (inline) {
    // For inline code, use PrismHighlighter for better syntax highlighting
    return (
      <span className="inline-block px-1.5 py-0.5 rounded bg-gray-800 font-mono text-sm border border-gray-700 text-gray-200">
        <PrismHighlighter
          language="text"
          style={{
            'code[class*="language-"]': {
              background: 'transparent',
              padding: 0,
              margin: 0,
              fontSize: '0.875rem',
              color: '#f06292',  // Light pink for better contrast on dark background
              fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace'
            },
            'pre[class*="language-"]': {
              background: 'transparent',
              padding: 0,
              margin: 0
            }
          }}
          PreTag="span"
          className="!bg-transparent"
        >
          {content}
        </PrismHighlighter>
      </span>
    )
  }

  if (!match) {
    return (
      <code className="px-1.5 py-0.5 rounded bg-gray-800 font-mono text-sm text-gray-200 border border-gray-700">
        {children}
      </code>
    )
  }

  const codeContent = content.replace(/\n$/, '')

  return (
    <div className="rounded-lg overflow-hidden shadow-sm">
      <div className="p-4" style={inverseStyle['pre[class*="language-"]']}>
        <PrismHighlighter
          language={match[1]}
          style={inverseStyle}
          PreTag="div"
          className="!bg-transparent"
        >
          {codeContent}
        </PrismHighlighter>
      </div>
    </div>
  )
}
