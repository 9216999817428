import { useState } from 'react'
import type React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import type { HomeWorkQuestions } from '../../../interfaces/mongoose.gen'
import type { AnswersState } from './types'

interface AnsweredQuestionsProps {
  questions: HomeWorkQuestions[]
  answers: AnswersState
  correctQuestionIds: string[]
}

const AnsweredQuestions: React.FC<AnsweredQuestionsProps> = ({ questions, answers, correctQuestionIds }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (correctQuestionIds.length === 0) {
    return null
  }

  const correctQuestions = questions.filter(q => q._id && correctQuestionIds.includes(q._id))

  return (
    <div className="mt-12 bg-gradient-to-r from-green-900/30 to-teal-900/30 p-6 rounded-xl shadow-lg border border-green-500/50">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-medium text-green-400 flex items-center">
          <svg className="w-6 h-6 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          Correctly Answered Questions ({correctQuestions.length})
        </h2>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames(
            "flex items-center px-3 py-1 rounded-md transition-all duration-200",
            "bg-gray-700 text-gray-200 hover:bg-gray-600 font-medium",
            "border border-gray-600"
          )}
        >
          {isExpanded ? 'Hide' : 'Show'} answers
          <svg 
            className={classNames("w-4 h-4 ml-2 transition-transform duration-300", {
              'rotate-180': isExpanded
            })} 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
      </div>
      
      {isExpanded && (
        <div className="space-y-4 mt-6 animate-fade-in">
          {correctQuestions.map((question) => {
            const questionId = question._id
            if (!questionId) return null

            return (
              <div 
                key={questionId}
                className="bg-green-900/20 border-l-4 border-green-500 rounded-lg p-4 shadow-inner"
              >
                <div className="flex justify-between">
                  <h3 className="text-lg font-medium text-white mb-2">
                    <ReactMarkdown>{question.question}</ReactMarkdown>
                  </h3>
                  <div className="flex-shrink-0 ml-4">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                      </svg>
                      Correct
                    </span>
                  </div>
                </div>
                <div className="text-gray-300 mt-2 pl-4 border-l-2 border-green-500/30">
                  <span className="font-medium text-green-400">Your answer: </span>
                  <span className="text-white">
                    {Array.isArray(answers[questionId]) 
                      ? (answers[questionId] as string[]).join(', ')
                      : answers[questionId]}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default AnsweredQuestions
