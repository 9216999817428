import { useNavigate } from 'react-router-dom'
import Header from './landings/default/sections/header'
import Footer from './landings/default/sections/footer'
import LoginForm from './login-form'

const LoginPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow bg-gradient-to-b from-white to-teal-50/20">
        <div className="container mx-auto flex justify-center items-center min-h-[calc(100vh-64px-80px)]">
          <div className="w-full py-12">
            <LoginForm />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LoginPage
