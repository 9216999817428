import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "./landings/default/sections/header";
import Footer from "./landings/default/sections/footer";
import { Mail, Lock, KeyRound } from "lucide-react";

const ForgotPassword = () => {
	const { email = "" } = useParams();
	const getInitialEmail = () => {
		if (email.length === 0) {
			return email;
		}
		return typeof atob === "undefined"
			? Buffer.from(email, "base64").toString()
			: atob(email);
	};

	const [data, setData] = useState({
		email: getInitialEmail(),
		password: "",
		repeatPassword: "",
		phase: "",
		error: "",
		code: "",
	});

	const [isVerified] = useState(
		typeof window === "undefined"
			? false
			: window.location.pathname.indexOf("verified") > 0,
	);

	const changeField = (
		fieldName: string,
	): React.ChangeEventHandler<HTMLInputElement> => {
		return ({ target }) => {
			setData({
				...data,
				[fieldName]: target.value,
			});
		};
	};

	const handleSubmitRequest = async () => {
		axios
			.post(
				"/api/v1/authentication/request-change-password",
				{
					email: data.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			)
			.then(() => {
				setData({ ...data, phase: "enter_password" });
			})
			.catch((error2) => {
				setData({ ...data, error: error2.response.data.message });
			});
	};

	const handleChangeRequest = async () => {
		axios
			.post(
				"/api/v1/authentication/confirm-change-password",
				{
					code: data.code,
					password: data.password,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				if (resp.data.status !== "ok") {
					setData({ ...data, error: resp.data.message });
				} else {
					setData({ ...data, error: "", phase: "done" });
				}
			})
			.catch((error2) => {
				setData({ ...data, error: error2.response.data.message });
			});
	};

	const handleSubmit1: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();
		await handleSubmitRequest();
	};

	const handleSubmitChange: React.FormEventHandler<HTMLFormElement> = async (
		e,
	) => {
		e.preventDefault();
		if (data.password !== data.repeatPassword) {
			setData({ ...data, error: "Passwords are not identical" });
		} else {
			await handleChangeRequest();
		}
	};

	const errorMessage = (
		<div className="mt-6 text-red-500 text-sm text-center" role="alert">
			{data.error}
		</div>
	);

	return (
		<div className="min-h-screen flex flex-col">
			<Header />
			<div className="flex-grow bg-gradient-to-b from-white to-teal-50/20">
				<div className="container mx-auto flex justify-center items-center min-h-[calc(100vh-64px-80px)]">
					<div className="w-full py-12">
						<div className="sm:mx-auto sm:w-full sm:max-w-md">
							<div className="backdrop-blur-sm bg-white/80 shadow-sm rounded-xl overflow-hidden">
								<div className="px-6 py-8 sm:px-10">
									<div className="mb-8 text-center">
										<h2 className="text-2xl font-medium text-gray-900">
											{data.phase === "enter_password"
												? "Reset Password"
												: data.phase === "done"
												? "Password Reset"
												: "Forgot Password"}
										</h2>
									</div>

									{isVerified && (
										<div className="mb-6 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded" role="alert">
											<p className="font-bold">Email verified</p>
											<p>You can now log in to your account</p>
										</div>
									)}

									{data.phase === "enter_password" && (
										<form className="space-y-6" onSubmit={handleSubmitChange}>
											<div>
												<div className="relative group">
													<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
														<KeyRound className="h-4 w-4 text-gray-400 group-hover:text-teal-500 transition-colors duration-200" aria-hidden="true" />
													</div>
													<input
														name="code"
														aria-label="code"
														value={data.code}
														onChange={changeField("code")}
														className="block w-full pl-10 pr-3 py-3 border border-gray-200 rounded-md bg-white/90 placeholder-gray-500 focus:outline-none focus:border-teal-400 focus:ring-1 focus:ring-teal-400 transition-all duration-200 sm:text-sm"
														placeholder="Code from Telegram"
													/>
												</div>
											</div>

											<div>
												<div className="relative group">
													<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
														<Lock className="h-4 w-4 text-gray-400 group-hover:text-teal-500 transition-colors duration-200" aria-hidden="true" />
													</div>
													<input
														name="password"
														aria-label="password"
														type="password"
														value={data.password}
														onChange={changeField("password")}
														className="block w-full pl-10 pr-3 py-3 border border-gray-200 rounded-md bg-white/90 placeholder-gray-500 focus:outline-none focus:border-teal-400 focus:ring-1 focus:ring-teal-400 transition-all duration-200 sm:text-sm"
														placeholder="New Password"
													/>
												</div>
											</div>

											<div>
												<div className="relative group">
													<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
														<Lock className="h-4 w-4 text-gray-400 group-hover:text-teal-500 transition-colors duration-200" aria-hidden="true" />
													</div>
													<input
														name="repeatPassword"
														aria-label="repeatPassword"
														type="password"
														value={data.repeatPassword}
														onChange={changeField("repeatPassword")}
														className="block w-full pl-10 pr-3 py-3 border border-gray-200 rounded-md bg-white/90 placeholder-gray-500 focus:outline-none focus:border-teal-400 focus:ring-1 focus:ring-teal-400 transition-all duration-200 sm:text-sm"
														placeholder="Confirm New Password"
													/>
												</div>
											</div>

											{data.error && errorMessage}

											<div className="flex items-center justify-between space-x-4">
												<Link
													className="text-xs text-teal-500 hover:text-teal-600 transition-colors duration-200"
													to="/login"
												>
													Back to login
												</Link>
												<button
													type="submit"
													className="flex-1 flex justify-center py-3 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 transition-all duration-200"
												>
													Reset Password
												</button>
											</div>
										</form>
									)}

									{data.phase === "done" && (
										<div className="space-y-6">
											<div className="text-center text-green-500 mb-6">
												<svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
												</svg>
												<p className="text-sm">Your password has been successfully reset.</p>
											</div>

											<div className="flex justify-center">
												<Link
													to="/login"
													className="w-full flex justify-center py-3 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 transition-all duration-200"
												>
													Back to Login
												</Link>
											</div>
										</div>
									)}

									{data.phase === "" && (
										<form className="space-y-6" onSubmit={handleSubmit1}>
											<div>
												<div className="relative group">
													<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
														<Mail className="h-4 w-4 text-gray-400 group-hover:text-teal-500 transition-colors duration-200" aria-hidden="true" />
													</div>
													<input
														name="email"
														aria-label="email"
														type="email"
														autoComplete="email"
														required
														value={data.email}
														onChange={changeField("email")}
														className="block w-full pl-10 pr-3 py-3 border border-gray-200 rounded-md bg-white/90 placeholder-gray-500 focus:outline-none focus:border-teal-400 focus:ring-1 focus:ring-teal-400 transition-all duration-200 sm:text-sm"
														placeholder="Email address"
													/>
												</div>
											</div>

											{data.error && errorMessage}

											<div className="flex items-center justify-between space-x-4">
												<Link
													className="text-xs text-teal-500 hover:text-teal-600 transition-colors duration-200"
													to="/login"
												>
													Back to login
												</Link>
												<button
													type="submit"
													className="flex-1 flex justify-center py-3 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-400 transition-all duration-200"
												>
													Send Reset Link
												</button>
											</div>
										</form>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ForgotPassword;
