import React from 'react';
import type { FC } from 'react';

interface FloatingSaveButtonProps {
  isVisible: boolean;
  onSave: () => void;
  isEditing: boolean;
  onToggleEdit: () => void;
}

const FloatingSaveButton: FC<FloatingSaveButtonProps> = ({ 
  isVisible, 
  onSave, 
  isEditing,
  onToggleEdit
}) => {
  if (!isVisible) return null;

  return (
    <div className="fixed bottom-20 right-6 flex flex-col gap-2 z-40">
      {isEditing && (
        <button
          type="button"
          onClick={onSave}
          className="flex items-center justify-center w-12 h-12 bg-green-600 hover:bg-green-500 text-white rounded-full shadow-lg transition-colors"
          title="Save changes"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <title>Save</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </button>
      )}
      
      <button
        type="button"
        onClick={onToggleEdit}
        className={`flex items-center justify-center w-12 h-12 ${
          isEditing 
            ? 'bg-gray-600 hover:bg-gray-500' 
            : 'bg-blue-600 hover:bg-blue-500'
        } text-white rounded-full shadow-lg transition-colors`}
        title={isEditing ? "Cancel editing" : "Edit lesson"}
      >
        {isEditing ? (
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <title>Cancel</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <title>Edit</title>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
        )}
      </button>
    </div>
  );
};

export default FloatingSaveButton;
