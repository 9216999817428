import type { User } from 'interfaces/mongoose.gen'
import type { AchievementKey } from './achievements'
import { useAppSelector } from '@data'
import Head from '../head'
import Achievements from './achievements'

interface UserHeaderProps {
  user: Partial<User> & { sid?: string | undefined }
}

const UserHeader = ({ user }: UserHeaderProps) => {
  const placeholder =
    user.sex === 'F'
      ? '/images/profile_placeholder_female.webp'
      : '/images/profile_placeholder_male.webp'

  return (
    <div className="flex items-center justify-between py-6">
      <div className="flex items-center space-x-4">
        <img className="h-16 w-16 rounded-full ring-2 ring-gray-200 dark:ring-gray-700" src={user.picture || placeholder} alt={user.name} />
        <div>
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{user.name}</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">{user.email}</p>
        </div>
      </div>
      <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
        ID: {user.homeworkId}
      </div>
    </div>
  )
}

const Profile = () => {
  const user = useAppSelector((state) => state.authentication.user)

  return (
    <div className="min-h-screen">
      <Head title="User Profile" />
      <div className=" mx-auto space-y-16">
        <UserHeader user={user} />

        <div className="flex justify-between">
          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Achievements</div>
            <div className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
              {(user.achievements ?? []).length}
            </div>
          </div>
          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Next Event</div>
            <div className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">N/A</div>
          </div>
          <div>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">Score</div>
            <div className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">N/A</div>
          </div>
        </div>

        {(user.achievements ?? [])?.length > 0 && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">My Achievements</h3>
            <Achievements achievements={(user.achievements ?? []) as AchievementKey[]} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile
