import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import Playground from 'javascript-playgrounds'

interface Props {
  code?: string
  defaultCode: string
  homeworkCode: string
  onChange?: (code: string) => void
  onSubmit?: (code: string) => void
  onReset?: () => void
  className?: string
  id: string
  hideSubmit?: boolean
  isTypeScript?: boolean
}

export interface EditorHandle {
  handleReset: () => void
}

interface PlaygroundMessage {
  type: string
  code?: string
  taskId?: string
}

const CodeEditor = forwardRef<EditorHandle, Props>(({
  code = '',
  defaultCode,
  homeworkCode,
  onChange = () => {},
  onSubmit,
  onReset,
  className = '',
  id,
  hideSubmit = false,
  isTypeScript = false
}, ref) => {
  const [currentCode, setCurrentCode] = useState(code)
  const [key, setKey] = useState(0)

  useEffect(() => {
    if (typeof code !== 'undefined' && code.length > 0) {
      setCurrentCode(code)
    }
    if (code !== currentCode) {
      setCurrentCode(code)
    }
  }, [code, currentCode])

  useEffect(() => {
    const onMessage = (event: MessageEvent<string>) => {
      if (event.origin !== 'https://unpkg.com') return
      try {
        const data = JSON.parse(event.data) as PlaygroundMessage
        if (data.code && data.taskId) {
          if (id !== data.taskId) return
          if (data.code !== defaultCode) {
            onChange(data.code)
            setCurrentCode(data.code)
          }
        }
      } catch (e) {
        // Ignore invalid JSON
      }
    }
    window.addEventListener('message', onMessage, false)
    return () => {
      window.removeEventListener('message', onMessage, false)
    }
  }, [defaultCode, id, onChange])

  // Reset code to default
  const handleReset = () => {
    // Update local state
    setCurrentCode(defaultCode)
    
    // Update parent state
    onChange(defaultCode)
    onReset?.()

    // Force playground re-render with default code
    setKey(prev => prev + 1)
  }

  // Expose handleReset to parent components
  useImperativeHandle(ref, () => ({
    handleReset
  }))

  return (
    <div className="h-full flex flex-col">
      <div className={`flex-1 relative bg-gray-900 rounded-lg overflow-hidden ${className}`}>
        <div className="editor-header bg-gray-800 px-4 py-2 flex items-center justify-between border-b border-gray-700">
          <div className="flex space-x-2">
            <div className="w-3 h-3 rounded-full bg-red-500" />
            <div className="w-3 h-3 rounded-full bg-yellow-500" />
            <div className="w-3 h-3 rounded-full bg-green-500" />
          </div>
          <div className="text-gray-400 text-sm">{isTypeScript ? 'TypeScript' : 'JavaScript'} Editor</div>
        </div>
        
        <div className="h-[calc(100%-40px)]">
          <Playground
            key={key}
            code={currentCode}
            targetOrigin="*"
            fullscreen
            preset={isTypeScript ? 'typescript' : 'javascript'}
            className="w-full h-full"
            style={{ height: '100%' }}
          />
        </div>
      </div>

      {!hideSubmit && typeof onSubmit !== 'undefined' && (
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="bg-teal-600 hover:bg-teal-500 text-white px-4 py-1.5 rounded flex items-center gap-2 transition-colors"
            onClick={(e) => {
              e.preventDefault()
              onSubmit(currentCode)
            }}
          >
            <span>Submit</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
              aria-label="Submit code"
              role="img"
            >
              <title>Submit your code</title>
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          </button>
        </div>
      )}
    </div>
  )
})

CodeEditor.displayName = 'CodeEditor'

export default CodeEditor
