import classNames from 'classnames'

interface SubmitButtonProps {
  isSubmitting: boolean
  isShaking: boolean
  answeredQuestions: number
  totalQuestions: number
  onClick: () => void
}

const SubmitButton = ({
  isSubmitting,
  isShaking,
  answeredQuestions,
  totalQuestions,
  onClick
}: SubmitButtonProps) => {
  return (
    <div className="mt-12 flex justify-center">
      <button
        type="button"
        onClick={onClick}
        disabled={answeredQuestions !== totalQuestions || isSubmitting}
        className={classNames(
          'px-8 py-4 rounded-xl font-medium text-white shadow-xl transition-all duration-300 text-lg relative overflow-hidden group',
          {
            'bg-red-600 hover:bg-red-700': isShaking,
            'bg-gradient-to-r from-teal-600 to-teal-500 hover:from-teal-500 hover:to-teal-400 transform hover:-translate-y-1': 
              answeredQuestions === totalQuestions && !isSubmitting && !isShaking,
            'bg-gray-700 cursor-not-allowed opacity-70': answeredQuestions !== totalQuestions || isSubmitting,
            'animate-shake': isShaking
          }
        )}
      >
        <span className="relative z-10 flex items-center">
          {isSubmitting ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                <title>Loading spinner</title>
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              Submitting...
            </>
          ) : answeredQuestions === totalQuestions ? (
            <>
              Submit Answers
              <svg className="ml-2 -mr-1 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <title>Arrow right</title>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </>
          ) : (
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30" height="30" className="mr-2">
                <circle cx="50" cy="50" r="40" fill="white" stroke="black" strokeWidth="2" />
                <circle cx="25" cy="25" r="12" fill="black" />
                <circle cx="75" cy="25" r="12" fill="black" />
                <ellipse cx="35" cy="45" rx="8" ry="10" fill="black" />
                <ellipse cx="65" cy="45" rx="8" ry="10" fill="black" />
                <circle cx="37" cy="42" r="2" fill="white" />
                <circle cx="67" cy="42" r="2" fill="white" />
                <ellipse cx="50" cy="55" rx="8" ry="5" fill="black" />
                <path d="M40,65 Q50,75 60,65" fill="none" stroke="black" strokeWidth="2" />
                <path d="M20,30 Q50,20 80,30" fill="none" stroke="red" strokeWidth="4" />
                <path d="M80,30 L90,40" fill="none" stroke="red" strokeWidth="4" />
              </svg>
              I am awesome
            </div>
          )}
        </span>
        {answeredQuestions === totalQuestions && !isSubmitting && !isShaking && (
          <span className="absolute inset-0 h-full w-full bg-gradient-to-r from-teal-400/20 to-transparent transform -translate-x-full group-hover:translate-x-0 transition-transform duration-700" />
        )}
      </button>
    </div>
  )
}

export default SubmitButton
